import React from 'react';
import 'fragments/admins-dashboard/fragments/admins-filter-modal/admins-filter-modal.scss';
import { AdminsFilterModalFragmentProps } from 'fragments/admins-dashboard/fragments/admins-filter-modal/interfaces';
import { useAdminsFilterModalController } from 'fragments/admins-dashboard/fragments/admins-filter-modal/admins-filter-modal.controller';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { Button, Col, Form, Modal, Row, Select, Tag, Typography } from 'antd';
import { LockDto } from 'services/locks/locks.service';
import { ITag } from 'global/interfaces';
const { Option } = Select;
const { Text, Title } = Typography;

export const AdminsFilterModalFragment: React.FC<AdminsFilterModalFragmentProps> = (props) => {
    const { useController = useAdminsFilterModalController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    const optionLocks = controller.locks.map((lock) => (
        <Option key={lock.id} value={lock.id}>
            <div style={{ display: 'flex', justifyContent: 'space-between', textTransform: 'capitalize' }}>
                {lock.name}
            </div>
        </Option>
    ));

    const forMap = (tag: ITag) => {
        const tagElem = (
            <Tag
                closable={controller.isTableLoading ? false : tag.closable}
                onClose={(e) => {
                    e.preventDefault();
                    controller.onCloseTag(tag);
                }}
                color={tag.color}
                style={{ fontWeight: 'bold' }}
            >
                <Text strong>{tag.name}: </Text> {tag.value}
            </Tag>
        );
        return (
            <span key={tag.id} style={{ display: 'inline-block', marginBottom: '2px' }}>
                {tagElem}
            </span>
        );
    };
    const tagChild = controller.tags.map(forMap);

    // Render
    return (
        <>
            <div
                style={{
                    width: '100%',
                    backgroundColor: '#f2f2f2',
                    padding: '10px',
                }}
            >
                <Text strong>{translate({ key: 'general.filters' })}:</Text>

                <div className="filter-tags">{tagChild}</div>
            </div>

            <Modal
                open={controller.isFilterModalVisible}
                destroyOnClose={true}
                title={
                    <Title level={4} style={{ padding: 0 }}>
                        {translate({ key: 'admin.filter-modal-title' })}
                    </Title>
                }
                className={'admins-filter-modal'}
                closable={false}
                centered
                footer={[
                    <Button
                        form="filter-admins-form"
                        key="reset"
                        className={'button primary'}
                        style={{ marginRight: 'auto' }}
                        loading={controller.isLoading}
                        onClick={controller.onResetButtonPressed}
                    >
                        {translate({ key: 'general.reset' })}
                    </Button>,
                    <Button key="back" className={'btn-cancel'} onClick={controller.onCancelButtonPressed}>
                        {translate({ key: 'general.cancel' })}
                    </Button>,
                    <Button
                        form="filter-admins-form"
                        key="submit"
                        type="primary"
                        className={'button primary'}
                        loading={controller.isLoading}
                        htmlType="submit"
                    >
                        {translate({ key: 'general.search' })}
                    </Button>,
                ]}
            >
                <Form
                    id="filter-admins-form"
                    form={controller.form}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    layout="horizontal"
                    size="middle"
                    className={'form'}
                    onFinish={controller.onSubmitButtonPressed}
                >
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.lock' })}
                            </Text>
                        }
                        name="lock_ids"
                        initialValue={controller.selectedLockIds}
                    >
                        <Select
                            placeholder={translate({ key: 'form.placeholder.lock.name' })}
                            mode="multiple"
                            allowClear
                            loading={controller.isLoadingLocks}
                            onChange={controller.onLockSelect}
                            style={{ width: '100%' }}
                            filterOption={(inputValue, option) => {
                                const opt: LockDto | undefined = controller.locks.find(
                                    (item) => item.id == option?.value,
                                );
                                console.log('filter value ', JSON.stringify(controller.selectedLockIds));
                                return !!opt?.name.toLowerCase().includes(inputValue.toLowerCase());
                            }}
                        >
                            {optionLocks}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.user' })}
                            </Text>
                        }
                        name="assigned_to"
                        initialValue={controller.searchInput}
                    >
                        <Select
                            showSearch
                            placeholder={translate({ key: 'form.placeholder.key.search-user-tags' })}
                            mode="tags"
                            allowClear
                            style={{ width: '100%' }}
                            onChange={controller.onSearchInputChange}
                            tokenSeparators={[',']}
                            dropdownStyle={{ display: 'none' }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.role' })}
                            </Text>
                        }
                        name="roles"
                        required
                        rules={[
                            {
                                required: !controller.selectedRoles.length ? true : false,
                                message: translate({ key: 'form.admin.select-role-error' }),
                            },
                        ]}
                    >
                        <Row style={{ gap: '10px' }}>
                            {controller.roleOptions.map((option) => {
                                return (
                                    <Col
                                        key={option.key}
                                        style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}
                                    >
                                        <Button
                                            type={option.selected ? 'primary' : 'default'}
                                            key={option.key}
                                            onClick={() => controller.onRoleOptionPressed(option.key)}
                                        >
                                            {option.label}
                                        </Button>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>
            {/* )} */}
        </>
    );
};
