import { useEffect, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { CheckboxOption, ITag } from 'global/interfaces';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { SearchRfidCardsInterface } from 'services/rfidcards/rfidcards.service';
import { useRfidCardsContext } from 'fragments/rfid-cards-dashboard/context/rfid-cards.context';
import { FormValues, RfidCardsFilterModalController } from './interfaces';
import moment from 'moment';

export const useRfidCardsFilterModalController = (): /* <--Dependency Injections  like services hooks */
RfidCardsFilterModalController => {
    /* State */

    const { translate } = useTranslator();
    const [isLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [rfidCardStatusOptions, setRfidCardStatusOptions] = useState<CheckboxOption[]>([]);
    const [checkAll, setCheckAll] = useState(false);
    const [indeterminate, setIndeterminate] = useState(true);
    const {
        isFilterModalVisible,
        selectedDevicesIds,
        dateRange,
        label,
        authorizer,
        pageSize,
        selectedRfidCardStatusOptions,
        cardNumber,
        setCardNumber,
        setSelectedRfidCardStatusOptions,
        setAuthorizer,
        setLabel,
        setIsFilterModalVisible,
        setDateRange,
        setSelectedDevicesIds,
        setCurrentPage,
        setPageSize,
        setSortedInfo,
        setOrder,
        setOrderBy,
        locks,
        isLoadingLocks,
        getLocks,
        getRfidCards,
        tags,
        setTags,
        isLoadingTable,
    } = useRfidCardsContext();

    const [form] = useForm();
    const [session] = useLocalSession();
    const { selectedSite } = session;

    const validateCardNumberRegExp = /^\d+$/;

    /* Listeners */
    useEffect(() => {
        if (session.token && selectedSite) {
            getLocks();
            resetStates();
        }
    }, [selectedSite]);

    useEffect(() => {
        if (isFilterModalVisible == false) return;
        form.setFieldsValue({
            devices_ids: selectedDevicesIds,
            label: label,
            card_number: cardNumber,
            authorizer: authorizer,
            date_picker: dateRange.length ? dateRange : [null, null],
            states: selectedRfidCardStatusOptions,
        });
        setIndeterminate(
            !!selectedRfidCardStatusOptions.length &&
                selectedRfidCardStatusOptions.length < defaultRfidCardStatusOptions.length,
        );
        setCheckAll(selectedRfidCardStatusOptions.length == defaultRfidCardStatusOptions.length);
        setSubmitDisabled(disableSubmitButton());
    }, [isFilterModalVisible]);

    //________________TAGS________________
    useEffect(() => {
        mapTags({
            devices: selectedDevicesIds,
            since: dateRange[0]?.format('YYYY-MM-DD') || '',
            until: dateRange[1]?.format('YYYY-MM-DD') || '',
            card: cardNumber,
            label,
            authorizer,
            status: selectedRfidCardStatusOptions,
        });
    }, [selectedDevicesIds, dateRange, label, cardNumber, authorizer, selectedRfidCardStatusOptions, locks]);

    // ________________Button Events___________________

    const onCancelButtonPressed = () => {
        form.setFieldsValue({
            devices_ids: selectedDevicesIds,
            label: label,
            card_number: cardNumber,
            authorizer: authorizer,
            date_picker: dateRange.length ? dateRange : [null, null],
            states: selectedRfidCardStatusOptions,
        });
        setCheckAll(selectedRfidCardStatusOptions.length == defaultRfidCardStatusOptions.length);
        setIsFilterModalVisible(false);
    };

    const onResetButtonPressed = () => {
        resetStates();
        getRfidCards({ pagination: { page: 0, pageSize }, orderTable: { order: undefined, orderBy: undefined } });
        setDefaultRfidCardStatusOptions();
        setIsFilterModalVisible(false);
    };

    const disableSubmitButton = (): boolean => {
        if (
            form.getFieldValue('authorizer')?.length ||
            form.getFieldValue('card_number')?.length ||
            (!!form.getFieldValue('date_picker') &&
                form.getFieldValue('date_picker')[0] !== null &&
                form.getFieldValue('date_picker')[1] !== null) ||
            form.getFieldValue('devices_ids')?.length ||
            form.getFieldValue('label')?.length ||
            form.getFieldValue('states')?.length
        ) {
            return false;
        }
        return true;
    };

    const onSubmitButtonPressed = (values: FormValues) => {
        setSelectedDevicesIds(values.devices_ids);
        setCardNumber(values.card_number);
        setLabel(values.label);
        setAuthorizer(values.authorizer);
        setDateRange(values.date_picker);
        setSelectedRfidCardStatusOptions(values.states);
        getRfidCards({
            pagination: { page: 0, pageSize },
            search: {
                devices: values.devices_ids,
                since: values.date_picker[0]?.format('YYYY-MM-DD') || '',
                until: values.date_picker[1]?.format('YYYY-MM-DD') || '',
                label: values.label,
                card: values.card_number,
                authorizer: values.authorizer,
                status: values.states,
            },
        });
        setIsFilterModalVisible(false);
    };
    // ________________Input Events___________________

    const onChangeFormValues = () => {
        setSubmitDisabled(disableSubmitButton());
    };

    const onCheckRfidCardStatusOptions = (list: CheckboxValueType[]) => {
        form.setFieldValue('states', list as number[]);
        setIndeterminate(!!list.length && list.length < rfidCardStatusOptions.length);
        setCheckAll(list.length === rfidCardStatusOptions.length);
        setSubmitDisabled(disableSubmitButton());
    };

    const onCheckAllRfidCardStatusOptions = (e: CheckboxChangeEvent) => {
        form.setFieldValue('states', e.target.checked ? rfidCardStatusOptions.map((item) => item.value) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
        setSubmitDisabled(disableSubmitButton());
    };

    /* Private Methods */
    // ________________TAGS___________________
    const onCloseTag = (tag: ITag) => {
        switch (tag.name) {
            case translate({ key: 'label.lock' }): {
                const newselectedDevicesIds = selectedDevicesIds.filter((lockId) => lockId !== tag.id);
                setSelectedDevicesIds(newselectedDevicesIds);
                getRfidCards({
                    pagination: { page: 0, pageSize },
                    search: {
                        devices: newselectedDevicesIds,
                        since: dateRange[0]?.format('YYYY-MM-DD') || '',
                        until: dateRange[1]?.format('YYYY-MM-DD') || '',
                        card: cardNumber,
                        label: label,
                        authorizer: authorizer,
                        status: selectedRfidCardStatusOptions,
                    },
                });
                break;
            }
            case translate({ key: 'label.card' }): {
                const newCardNumber = cardNumber.filter((searchTerm) => searchTerm !== tag.id);
                setCardNumber(newCardNumber);
                getRfidCards({
                    pagination: { page: 0, pageSize },
                    search: {
                        devices: selectedDevicesIds,
                        since: dateRange[0]?.format('YYYY-MM-DD') || '',
                        until: dateRange[1]?.format('YYYY-MM-DD') || '',
                        card: newCardNumber,
                        label: label,
                        authorizer: authorizer,
                        status: selectedRfidCardStatusOptions,
                    },
                });
                break;
            }
            case translate({ key: 'label.label' }): {
                const newLabel = label.filter((searchTerm) => searchTerm !== tag.id);
                setLabel(newLabel);
                getRfidCards({
                    pagination: { page: 0, pageSize },
                    search: {
                        devices: selectedDevicesIds,
                        since: dateRange[0]?.format('YYYY-MM-DD') || '',
                        until: dateRange[1]?.format('YYYY-MM-DD') || '',
                        card: cardNumber,
                        label: newLabel,
                        authorizer: authorizer,
                        status: selectedRfidCardStatusOptions,
                    },
                });
                break;
            }
            case translate({ key: 'label.responsible' }): {
                const newAuthorizer = authorizer.filter((searchTerm) => searchTerm !== tag.id);
                setAuthorizer(newAuthorizer);
                getRfidCards({
                    pagination: { page: 0, pageSize },
                    search: {
                        devices: selectedDevicesIds,
                        since: dateRange[0]?.format('YYYY-MM-DD') || '',
                        until: dateRange[1]?.format('YYYY-MM-DD') || '',
                        card: cardNumber,
                        label: label,
                        authorizer: newAuthorizer,
                        status: selectedRfidCardStatusOptions,
                    },
                });
                break;
            }
            case translate({ key: 'label.duration' }): {
                setDateRange([null, null]);
                getRfidCards({
                    pagination: { page: 0, pageSize },
                    search: {
                        since: '',
                        until: '',
                        card: cardNumber,
                        label: label,
                        authorizer: authorizer,
                        devices: selectedDevicesIds,
                        status: selectedRfidCardStatusOptions,
                    },
                });
                break;
            }

            case translate({ key: 'label.state' }): {
                const newSelectedRfidCardStatus = selectedRfidCardStatusOptions.filter((id) => id !== tag.id);
                setSelectedRfidCardStatusOptions(newSelectedRfidCardStatus);
                getRfidCards({
                    pagination: { page: 0, pageSize },
                    search: {
                        devices: selectedDevicesIds,
                        since: dateRange[0]?.format('YYYY-MM-DD') || '',
                        until: dateRange[1]?.format('YYYY-MM-DD') || '',
                        card: cardNumber,
                        label: label,
                        authorizer: authorizer,
                        status: newSelectedRfidCardStatus,
                    },
                });
                break;
            }
            default:
                break;
        }
    };

    const mapTags = (search: SearchRfidCardsInterface) => {
        const { devices: selectedDevicesIds, since, until, card, label, authorizer, status } = search;
        const tempLockTags: ITag[] = [];
        const tempLabelTags: ITag[] = [];
        const tempCardTags: ITag[] = [];
        const tempAuthorizerTags: ITag[] = [];
        const tempDateTags: ITag[] = [];
        const tempSelectedRfidCardStatus: ITag[] = [];

        selectedDevicesIds.map((id) => {
            const selectedLock = locks.find((lock) => lock.id === id);
            tempLockTags.push({
                id,
                name: translate({ key: 'label.lock' }),
                value: `${selectedLock?.name}`,
                color: 'geekblue',
                closable: true,
            });
        });

        label.map((searchTerm) => {
            tempLabelTags.push({
                id: searchTerm,
                name: translate({ key: 'label.label' }),
                value: searchTerm,
                color: 'purple',
                closable: true,
            });
        });

        card.map((searchTerm) => {
            tempCardTags.push({
                id: searchTerm,
                name: translate({ key: 'label.card' }),
                value: searchTerm.toString(),
                color: 'cyan',
                closable: true,
            });
        });

        authorizer.map((searchTerm) => {
            tempAuthorizerTags.push({
                id: searchTerm,
                name: translate({ key: 'label.responsible' }),
                value: searchTerm,
                color: 'purple',
                closable: true,
            });
        });

        if (since !== '' || until !== '') {
            tempDateTags.push({
                id: since + until,
                name: translate({ key: 'label.duration' }),
                value: `Desde: ${moment(since).format('DD/MM/YYYY')} Hasta: ${moment(until).format('DD/MM/YYYY')}`,
                color: 'cyan',
                closable: true,
            });
        }
        if (status.length !== rfidCardStatusOptions.length) {
            status.map((value) => {
                const selectedRfidCardStatus = rfidCardStatusOptions.find((option) => option.value === value);
                tempSelectedRfidCardStatus.push({
                    id: value,
                    name: translate({ key: 'label.state' }),
                    value: `${selectedRfidCardStatus?.label}`,
                    color: 'green',
                    closable: true,
                });
            });
            if (tempSelectedRfidCardStatus.length === 1) {
                tempSelectedRfidCardStatus[0].closable = false;
            }
        }

        setTags([
            ...tempLockTags,
            ...tempLabelTags,
            ...tempCardTags,
            ...tempAuthorizerTags,
            ...tempDateTags,
            ...tempSelectedRfidCardStatus,
        ]);
    };

    // ________________Form___________________
    const defaultRfidCardStatusOptions: CheckboxOption[] = [
        { value: 1, label: translate({ key: 'rfid-card.label.pending' }) },
        { value: 2, label: translate({ key: 'rfid-card.label.valid' }) },
        { value: 3, label: translate({ key: 'rfid-card.label.deleted' }) },
        { value: 4, label: translate({ key: 'rfid-card.label.overwritten' }) },
        { value: 5, label: translate({ key: 'rfid-card.label.expired' }) },
    ];

    const setDefaultRfidCardStatusOptions = (): void => {
        setRfidCardStatusOptions(defaultRfidCardStatusOptions);
    };

    const resetStates = () => {
        setAuthorizer([]);
        setLabel([]);
        setCardNumber([]);
        setSelectedDevicesIds([]);
        setSelectedRfidCardStatusOptions([]);
        setDateRange([null, null]);
        setCurrentPage(0);
        setPageSize(10);
        setSortedInfo({
            order: undefined,
            columnKey: undefined,
        });
        setOrder(undefined);
        setOrderBy(undefined);
        setDefaultRfidCardStatusOptions();
    };
    // Return state and events
    return {
        tags,
        form,
        isLoading,
        locks,
        isLoadingLocks,
        isFilterModalVisible,
        selectedDevicesIds,
        label,
        cardNumber,
        submitDisabled,
        authorizer,
        rfidCardStatusOptions,
        isLoadingTable,
        dateRange,
        validateCardNumberRegExp,
        onCancelButtonPressed,
        onSubmitButtonPressed,
        onResetButtonPressed,
        onCloseTag,
        checkAll,
        indeterminate,
        onCheckRfidCardStatusOptions,
        onCheckAllRfidCardStatusOptions,
        onChangeFormValues,
    };
};
