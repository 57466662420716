import React, { ReactNode } from 'react';
import 'fragments/common/create-key/create-key.scss';
import {
    CreateKeyFragmentProps,
    CreateKeySummaryDto,
    CreateKeySummaryTableRecord,
} from 'fragments/common/create-key/interfaces';
import { useCreateKeyController } from 'fragments/common/create-key/create-key.controller';
import {
    Button,
    Modal,
    Row,
    Col,
    Typography,
    Form,
    DatePicker,
    Select,
    Statistic,
    Table,
    Slider,
    Steps,
    Radio,
    Tooltip,
    Divider,
} from 'antd';
import {
    WarningOutlined,
    DownCircleOutlined,
    CheckCircleTwoTone,
    WarningTwoTone,
    StopTwoTone,
    LoadingOutlined,
} from '@ant-design/icons';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import dayjs from 'dayjs';
const { Title, Text } = Typography;
const { Option } = Select;
const { Step } = Steps;

export const CreateKeyFragment: React.FC<CreateKeyFragmentProps> = (props) => {
    const { useController = useCreateKeyController, isModalVisible, onClose, lockId, onFinish } = props;
    const controller = useController(onClose, lockId, onFinish);
    const { translate } = useTranslator();

    const columns = [
        {
            title: 'Descripción',
            dataIndex: 'title',
        },
    ];

    const formatSummaryRecord = (value: CreateKeySummaryDto): JSX.Element => {
        let x: JSX.Element;
        switch (value.status) {
            case 0:
                x = (
                    <div>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        <Text> Llave generada para </Text>
                        <Text strong>{value.email}</Text>
                        <Text> sobre </Text>
                        <Text strong>{value.lock}</Text>
                    </div>
                );
                break;
            case 1:
                x = (
                    <div>
                        <StopTwoTone twoToneColor="#f60b07" />
                        <Text strong> {value.email}</Text>
                        <Text> no es un usuario válido en la plataforma.</Text>
                    </div>
                );
                break;
            case 2:
            default:
                x = (
                    <div>
                        <WarningTwoTone twoToneColor="#f6ad07" />
                        <Text strong> {value.email}</Text>
                        <Text> ya tiene una llave para </Text>
                        <Text strong>{value.lock}</Text>
                        <Text>, deseas sobreescribirla?</Text>
                    </div>
                );
        }
        return x;
    };

    const mapDataToShow = (dto: CreateKeySummaryDto, i: number): CreateKeySummaryTableRecord => {
        return {
            key: i,
            title: formatSummaryRecord(dto),
            disabled: dto.status != 2,
        };
    };

    const dataToShow: CreateKeySummaryTableRecord[] = controller.data.map(mapDataToShow);

    //_________________________________________________________________________
    const optionLocks = controller.locks.map((lock) => (
        <Option key={lock.id} value={lock.id}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    textTransform: 'capitalize',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'left',
                        textTransform: 'capitalize',
                        alignItems: 'left',
                    }}
                >
                    {lock.name}
                </div>
                <div
                    style={{
                        paddingLeft: '5px',
                        display: 'flex',
                        justifyContent: 'right',
                        textTransform: 'capitalize',
                        alignItems: 'right',
                    }}
                >
                    {lock.lock_status_id == 0 && <DownCircleOutlined style={{ color: '#cf1411' }} />}
                    {lock.lock_type_id == 5 && <WarningOutlined style={{ color: '#db7c00' }} />}
                </div>
            </div>
        </Option>
    ));

    const sliderFormatter = (value?: number): ReactNode => {
        const hours: number = Math.floor(value! / 60);
        const finalMinutes: number = Math.floor(value! % 60);
        const formatterTime: string = dayjs().set('hour', hours).set('minute', finalMinutes).format('HH:mm');
        return <div>{formatterTime}</div>;
    };

    // Render
    return (
        <Modal
            open={isModalVisible}
            destroyOnClose={true}
            title={
                <Title level={4} style={{ padding: 0 }}>
                    {translate({ key: 'key.create-title' })}
                </Title>
            }
            className={'create-key'}
            closable={false}
            centered
            footer={[
                controller.currentStep <= 1 && (
                    <Button
                        form="create-key-form"
                        key="cancel"
                        className={'btn-cancel'}
                        onClick={controller.onCancelButtonPressed}
                    >
                        {translate({ key: 'general.cancel' })}
                    </Button>
                ),
                controller.currentStep === 0 && (
                    <Button
                        form="create-key-form"
                        key="next"
                        type="primary"
                        className={'button primary'}
                        onClick={controller.onNextStepButtonPressed}
                        disabled={
                            !controller.selectedLockIds.length ||
                            !controller.selectedClientEmails.length ||
                            !!controller.invalidEmails.length
                        }
                    >
                        {translate({ key: 'general.next' })}
                    </Button>
                ),
                controller.currentStep === 1 && (
                    <Button
                        form="create-key-form"
                        key="back"
                        className={'btn-cancel'}
                        onClick={controller.onPrevStepButtonPressed}
                    >
                        {translate({ key: 'general.back' })}
                    </Button>
                ),
                controller.currentStep === 1 && (
                    <Button
                        form="create-key-form"
                        key="submit"
                        type="primary"
                        className={'button primary'}
                        loading={controller.isLoading}
                        htmlType="submit"
                    >
                        {translate({ key: 'general.add' })}
                    </Button>
                ),
                controller.currentStep === 2 && controller.warningCount > 0 && (
                    <Button
                        key="use"
                        type="primary"
                        onClick={controller.onContinue}
                        disabled={!controller.isContinueEnabled || !controller.warningCount}
                        loading={controller.isDataSending}
                    >
                        {translate({ key: 'general.overwrite' })} {controller.selectedRows.length}/
                        {controller.warningCount}
                    </Button>
                ),
                controller.currentStep === 2 && (
                    <Button
                        key="cancel-overwrite"
                        type="primary"
                        onClick={controller.onCloseModal}
                        disabled={controller.isCancelLoading}
                        loading={controller.isCancelLoading}
                    >
                        {translate({ key: 'general.close' })}{' '}
                        {controller.cancelTimeout > 0 ? controller.cancelTimeout : ''}
                    </Button>
                ),
            ]}
        >
            <Steps current={controller.currentStep} style={{ marginBottom: '20px' }} size="small">
                <Step title="Inicio" />
                <Step title="Configuracion" icon={controller.isLoading && <LoadingOutlined />} />
                <Step title="Resumen" />
            </Steps>
            {controller.currentStep <= 1 ? (
                <Form
                    id="create-key-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    layout="horizontal"
                    size="middle"
                    className={'form'}
                    onFinish={controller.onCreateKeyFormSubmit}
                    form={controller.form}
                    initialValues={{ 'time-picker': [dayjs().startOf('day'), dayjs().endOf('day')] }}
                >
                    <Col
                        id="create-key-lock-select"
                        style={{
                            display: controller.currentStep !== 0 ? 'none' : '',
                        }}
                    >
                        <Form.Item
                            label={
                                <Text type="secondary" strong>
                                    {translate({ key: 'label.lock' })}
                                </Text>
                            }
                            rules={[
                                {
                                    required: !controller.selectedLockIds.length ? true : false,
                                    message: !controller.selectedLockIds.length
                                        ? translate({ key: 'form.rules-required' })
                                        : '',
                                },
                            ]}
                            required
                            // rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                            name="lockName"
                        >
                            <Select
                                showSearch
                                placeholder={translate({ key: 'form.placeholder.lock.name' })}
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                value={controller.selectedLockIds}
                                getPopupContainer={() =>
                                    document.getElementById(`create-key-lock-select`) as HTMLElement
                                }
                                onChange={controller.onLockSelect}
                                filterOption={(inputValue, option) => {
                                    const opt = controller.locks.find((item) => item.id == option?.value);
                                    return !!opt?.name.toLowerCase().includes(inputValue.toLowerCase());
                                }}
                                defaultValue={controller.selectedLockIds}
                                loading={controller.isLockListingLoading}
                            >
                                {optionLocks}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Text
                        strong
                        italic
                        style={{
                            display: controller.currentStep !== 0 ? 'none' : '',
                        }}
                    >
                        {translate({ key: 'key.create-disabled-lock-warning' }).split(',')[0]}{' '}
                        <DownCircleOutlined style={{ color: '#cf1411' }} />
                        {translate({ key: 'key.create-disabled-lock-warning' }).split(',')[1]}
                    </Text>
                    <br></br>
                    <Text
                        strong
                        italic
                        style={{
                            display: controller.currentStep !== 0 ? 'none' : '',
                        }}
                    >
                        {translate({ key: 'key.create-gt-warning' }).split(',')[0]}{' '}
                        <WarningOutlined style={{ color: '#db7c00' }} />
                        {translate({ key: 'key.create-gt-warning' }).split(',')[1]}
                    </Text>
                    <Divider
                        style={{
                            margin: '8px 0',
                            display: controller.currentStep !== 0 ? 'none' : '',
                        }}
                    />
                    <Form.Item
                        style={{
                            display: controller.currentStep !== 0 ? 'none' : '',
                        }}
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.client-email' })}
                            </Text>
                        }
                        rules={[
                            {
                                required: true,
                                message: translate({ key: 'form.rules-required' }),
                            },
                            () => ({
                                validator(_, value) {
                                    const invalidEmails = value.filter(
                                        (email: string) => !controller.validateEmailRegExp.test(email.toLowerCase()),
                                    );
                                    const invalidEmailsString = invalidEmails.join(', ');
                                    if (!invalidEmails.length) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(`Emails Invalidos: ${invalidEmailsString}`));
                                },
                            }),
                        ]}
                        required
                        name="client-email"
                    >
                        <Select
                            mode="tags"
                            style={{ width: '100%' }}
                            allowClear
                            value={controller.selectedClientEmails}
                            onChange={controller.onClientEmailAdd}
                            tokenSeparators={[' ']}
                            placeholder={translate({ key: 'form.placeholder.key.client-email' })}
                            dropdownStyle={{ display: 'none' }}
                        />
                    </Form.Item>
                    <Col
                        xs={24}
                        id="create-key-date-picker"
                        style={{
                            display: controller.currentStep !== 1 ? 'none' : '',
                        }}
                    >
                        <Form.Item
                            label={
                                <Text type="secondary" strong>
                                    {translate({ key: 'label.duration' })}
                                </Text>
                            }
                            required
                            rules={[
                                {
                                    required: !controller.dateRange.to,
                                    message: translate({ key: 'form.rules-required' }),
                                },
                            ]}
                            name="date-picker"
                        >
                            <>
                                <Radio.Group
                                    buttonStyle="solid"
                                    onChange={controller.onSelectDuration}
                                    className={'duration-radio-group'}
                                >
                                    <Radio.Button value={0}>Hoy</Radio.Button>
                                    <Radio.Button value={1}>Mañana</Radio.Button>
                                    <Radio.Button value={2}>1 mes</Radio.Button>
                                    <Radio.Button value={3}>3 meses</Radio.Button>
                                    <Radio.Button value={4}>6 meses</Radio.Button>
                                    <Radio.Button value={5}>1 año</Radio.Button>
                                    <Radio.Button value={6}>2 años</Radio.Button>
                                    <Radio.Button value={7}>Elegir</Radio.Button>
                                </Radio.Group>
                                <DatePicker.RangePicker
                                    style={{
                                        marginTop: '1rem',
                                        width: '100%',
                                        display: !controller.isDatePickerVisible ? 'none' : '',
                                    }}
                                    onChange={controller.onPickedDate}
                                    getPopupContainer={() =>
                                        document.getElementById('create-key-date-picker') as HTMLElement
                                    }
                                    format="DD-MM-YYYY"
                                    disabledDate={controller.disabledDate}
                                />
                            </>
                        </Form.Item>
                        <Text
                            style={{
                                display: controller.dateRange.from.length ? 'block' : 'none',
                                width: '100%',
                                textAlign: 'right',
                            }}
                        >
                            {controller.dateRange.from == controller.dateRange.to ? (
                                <>
                                    {`Llave para: `}
                                    <b>{`${controller.dowString} ${controller.dateRange.from}`}</b>{' '}
                                </>
                            ) : (
                                <>
                                    {`Desde `}
                                    <b>{controller.dateRange.from}</b>
                                    {` hasta `}
                                    <b>{controller.dateRange.to}</b>
                                </>
                            )}
                        </Text>
                    </Col>
                    <Divider style={{ margin: '8px 0', display: controller.currentStep !== 1 ? 'none' : '' }} />
                    <Form.Item
                        style={{
                            display:
                                controller.currentStep !== 1 || controller.dateRange.from == controller.dateRange.to
                                    ? 'none'
                                    : '',
                        }}
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.availability' })}
                            </Text>
                        }
                        required
                        rules={[
                            {
                                required: controller.dowBytewise == 0 ? true : false,
                                message: controller.dowBytewise == 0 ? translate({ key: 'form.rules-required' }) : '',
                            },
                        ]}
                        name="days-of-week"
                    >
                        <Row justify="space-between">
                            {controller.keySchedules.map((keyOption) => {
                                return (
                                    <Col
                                        xs={3}
                                        key={keyOption.key}
                                        style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}
                                    >
                                        <Tooltip title={keyOption.label}>
                                            <Button
                                                type={keyOption.selected ? 'primary' : 'default'}
                                                shape={'circle'}
                                                size={'large'}
                                                key={keyOption.key}
                                                onClick={() => controller.onDayPressed(keyOption.key)}
                                                disabled={keyOption.disabled}
                                            >
                                                {keyOption.label.charAt(0)}
                                            </Button>
                                        </Tooltip>
                                    </Col>
                                );
                            })}
                        </Row>
                        <Text
                            style={{
                                display: controller.dowString.length ? 'block' : 'none',
                                width: '100%',
                                textAlign: 'right',
                            }}
                        >
                            {controller.dowString}
                        </Text>
                        <Button
                            style={{ float: 'right', marginTop: '10px' }}
                            size={'small'}
                            className={'button primary'}
                            type="primary"
                            onClick={controller.onWeekdaysButtonPressed}
                            hidden={controller.disabledDowButtons || controller.dowBytewise === 31}
                        >
                            {translate({ key: 'general.weekdays' })}
                        </Button>
                    </Form.Item>
                    <Divider
                        style={{
                            margin: '8px 0',
                            display:
                                controller.currentStep !== 1 || controller.dateRange.from == controller.dateRange.to
                                    ? 'none'
                                    : '',
                        }}
                    />
                    <Col
                        xs={24}
                        id={`create-key-time-picker`}
                        style={{
                            display: controller.currentStep !== 1 ? 'none' : '',
                        }}
                    >
                        <Form.Item
                            label={
                                <Title level={5} type="secondary" style={{ marginBottom: '0px' }}>
                                    {translate({ key: 'Horario' })}
                                </Title>
                            }
                            // rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                            name="time-picker"
                        >
                            <Slider
                                range
                                defaultValue={[0, 1439]}
                                marks={controller.marks}
                                tipFormatter={sliderFormatter}
                                min={0}
                                max={1439}
                                step={15}
                                onChange={controller.onSetTimeSlider}
                            />
                        </Form.Item>
                    </Col>
                </Form>
            ) : (
                <div>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Statistic
                                title="Advertencias"
                                value={controller.warningCount}
                                valueStyle={{ color: '#f6ad07' }}
                                loading={controller.isDataSending}
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic
                                title="Errores"
                                value={controller.errorCount}
                                valueStyle={{ color: '#f60b07' }}
                                loading={controller.isDataSending}
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic
                                title="Exitos"
                                value={controller.successCount}
                                valueStyle={{ color: '#52c41a' }}
                                loading={controller.isDataSending}
                            />
                        </Col>
                    </Row>
                    <Table
                        rowSelection={{
                            type: 'checkbox',
                            // hideSelectAll: true,
                            onChange: controller.onSelectChange,
                            getCheckboxProps: (record: CreateKeySummaryTableRecord) => ({
                                disabled: record.disabled, // Column configuration not to be checked
                                // title: record.title + 'asd',
                            }),
                        }}
                        columns={columns}
                        dataSource={dataToShow}
                        scroll={{ x: 150, y: 500 }}
                        pagination={false}
                        loading={controller.isDataSending}
                    />
                </div>
            )}
        </Modal>
    );
};
