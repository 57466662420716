import { useLocalSession } from 'auth/helpers/session.hooks';
import axios from 'axios';

import {
    CheckDuplicatedRfidCard,
    CreateRfidCardInput,
    CreateRfidCardResponse,
    DeleteRfidCardResponse,
    DeleteSiteRfidCardResponse,
    PaginatedRfidCardsLocksResponse,
    PaginatedSiteRfidCardsResponse,
    RfidCards,
    RfidCardsListInput,
} from './rfidcards.service';
import { ErrorDto } from 'services/dtos/errors..dto';

export const useAPIRfidcard = (): RfidCards => {
    const [session] = useLocalSession();
    //const errorHandler = useErrorHandler();

    const listSiteRfidCards = async ({
        pagination,
        orderTable,
        search,
    }: RfidCardsListInput): Promise<PaginatedSiteRfidCardsResponse> => {
        orderTable = orderTable || { order: 'descend', orderBy: 'since' };
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_RFID_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/rfid-cards?page=${pagination.page}&pageSize=${
                        pagination.pageSize
                    }&card=${JSON.stringify(search.card)}&label=${JSON.stringify(
                        search.label,
                    )}&devices=${JSON.stringify(search.devices)}&status=${JSON.stringify(search.status)}&order=${
                        orderTable.order
                    }&orderBy=${orderTable.orderBy}&search=${search.search || ''}`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as PaginatedSiteRfidCardsResponse;
        } catch (err: unknown) {
            // errorHandler(err);
            // const error = err as ErrorDto;
            // throw error;
            throw err;
        }
    };

    const listValidSiteRfidCards = async ({
        pagination,
        orderTable,
        search,
    }: RfidCardsListInput): Promise<PaginatedSiteRfidCardsResponse> => {
        orderTable = orderTable || { order: 'ascend', orderBy: 'label' };
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_RFID_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/rfid-cards?page=${pagination.page}&pageSize=${
                        pagination.pageSize
                    }&card=${JSON.stringify(search.card)}&label=${JSON.stringify(search.label)}&locks=${JSON.stringify(
                        search.devices,
                    )}&status=${JSON.stringify([1])}&order=${orderTable.order}&orderBy=${orderTable.orderBy}&search=${
                        search.search || ''
                    }`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as PaginatedSiteRfidCardsResponse;
        } catch (err: unknown) {
            // errorHandler(err);
            // const error = err as ErrorDto;
            // throw error;
            throw err;
        }
    };

    const listRfidCardsLocks = async ({
        pagination,
        orderTable,
        search,
    }: RfidCardsListInput): Promise<PaginatedRfidCardsLocksResponse> => {
        orderTable = orderTable || { order: 'descend', orderBy: 'since' };
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_RFID_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/rfid-cards-devices?page=${pagination.page}&pageSize=${
                        pagination.pageSize
                    }&card=${JSON.stringify(search.card)}&label=${JSON.stringify(
                        search.label,
                    )}&devices=${JSON.stringify(search.devices)}&authorizer=${JSON.stringify(
                        search.authorizer,
                    )}&status=${JSON.stringify(search.status)}&order=${orderTable.order}&orderBy=${
                        orderTable.orderBy
                    }&since=${search.since}&until=${search.until}`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as PaginatedRfidCardsLocksResponse;
        } catch (err: unknown) {
            throw err;
        }
    };

    const createRfidCard = async (input: CreateRfidCardInput): Promise<CreateRfidCardResponse> => {
        const { prev_event_id, ...rest } = input;
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_RFID_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/cards?prev_event_id=${prev_event_id || ''}`,
                { ...rest },
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as CreateRfidCardResponse;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    const deleteRfidCard = async (cardId: string, deviceId: string): Promise<DeleteRfidCardResponse> => {
        try {
            const response = await axios.delete(
                process.env.REACT_APP_API_RFID_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/devices/${deviceId}/cards/${cardId}`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as unknown as DeleteRfidCardResponse;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    const deleteSiteRfidCard = async (siteCardId: string): Promise<DeleteSiteRfidCardResponse> => {
        try {
            const response = await axios.delete(
                process.env.REACT_APP_API_RFID_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/rfid-cards/${siteCardId}`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as unknown as DeleteSiteRfidCardResponse;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    const getDeletingSiteRfidCard = async (siteCardId: string): Promise<DeleteSiteRfidCardResponse> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_RFID_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/rfid-cards/${siteCardId}/deleting`,
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
            return response.data as unknown as DeleteSiteRfidCardResponse;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    const checkDuplicatedRfidCard = async ({ lockId, label, accessToken }: CheckDuplicatedRfidCard): Promise<void> => {
        try {
            await axios.get(
                process.env.REACT_APP_API_WEB_BASE_URL +
                    `/user/locks/${lockId}/rfid-card/checkDuplicated?label=${label}`,
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    const restoreRfidCardStatusId = async (siteCardId: string): Promise<void> => {
        try {
            await axios.put(
                process.env.REACT_APP_API_RFID_BASE_URL +
                    `/user/sites/${session.selectedSite?.id}/rfid-cards/${siteCardId}/restore`,
                {},
                {
                    headers: { Authorization: 'Bearer ' + session.token },
                },
            );
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error.response.data;
        }
    };

    return {
        listRfidCardsLocks,
        listSiteRfidCards,
        createRfidCard,
        deleteRfidCard,
        deleteSiteRfidCard,
        getDeletingSiteRfidCard,
        checkDuplicatedRfidCard,
        listValidSiteRfidCards,
        restoreRfidCardStatusId,
    };
};
