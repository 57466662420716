import React from 'react';
import { CSVLink } from 'react-csv';
import 'fragments/download-reports/download-reports.scss';
import { DownloadReportsFragmentProps } from 'fragments/download-reports/interfaces';
import { useDownloadReportsController } from 'fragments/download-reports/download-reports.controller';
import { Button, Checkbox, Divider, Form, Radio, Select, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
const { Option } = Select;
const { Text, Title } = Typography;
const CheckboxGroup = Checkbox.Group;

export const DownloadReportsFragment: React.FC<DownloadReportsFragmentProps> = (props) => {
    const { useController = useDownloadReportsController } = props;
    const controller = useController();

    const { translate } = useTranslator();

    const optionUser = controller.users.map((user) => (
        <Option key={user.id} value={user.id}>
            <div style={{ display: 'flex', justifyContent: 'space-between', textTransform: 'capitalize' }}>
                {user.full_name}
            </div>
        </Option>
    ));

    const optionMonth = controller.optionMonth.map((month, index) => (
        <Radio.Button key={index} value={index}>
            {month.label}
        </Radio.Button>
    ));

    // Render
    return (
        <div className={'download-reports'}>
            <Title level={4} style={{ padding: 0 }}>
                {translate({ key: 'access-reports.download-reports' })}
            </Title>
            <Form
                id="filrter-access-reports-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                size="middle"
                className={'form'}
                form={controller.form}
                onFinish={controller.onSubmitButtonPressed}
            >
                <Form.Item
                    label={
                        <Text type="secondary" strong>
                            {translate({ key: 'general.month' })}
                        </Text>
                    }
                    required
                    rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                    name="month"
                >
                    <Radio.Group onChange={controller.onMonthSelect}>{optionMonth}</Radio.Group>
                </Form.Item>
                {controller.toggleDownload && (
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.access-method' })}
                            </Text>
                        }
                        name="accessMethodCheck"
                        required
                        rules={[
                            {
                                required: !controller.selectedAccessMethodIds.length ? true : false,
                                message: translate({ key: 'form.select-an-option-error' }),
                            },
                        ]}
                    >
                        <>
                            <Checkbox
                                indeterminate={controller.indeterminate}
                                onChange={controller.onCheckAllAccessMethodOption}
                                checked={controller.checkAllAccessMethod}
                            >
                                Seleccionar todos
                            </Checkbox>
                            <Divider style={{ margin: '10px' }} />
                            <CheckboxGroup
                                options={controller.accessMethodOptions}
                                value={controller.selectedAccessMethodIds}
                                onChange={controller.onCheckAccessMethodOption}
                            />
                        </>
                    </Form.Item>
                )}
                {controller.showSelect && (
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.user' })}
                            </Text>
                        }
                        name="user"
                        required
                        rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                    >
                        <Select
                            showSearch
                            placeholder={translate({ key: 'form.placeholder.access-report.user' })}
                            allowClear
                            loading={controller.isLoading}
                            value={controller.selectedUser}
                            onChange={controller.onUserSelect}
                            style={{ width: '100%' }}
                            filterOption={(inputValue, option) => {
                                const opt = controller.users.find((item) => item.id == option?.value);
                                return !!opt?.full_name.toLowerCase().includes(inputValue.toLowerCase());
                            }}
                        >
                            <Option key={'default'} value={'all'}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {translate({ key: 'label.all-users' })}
                                </div>
                            </Option>
                            {optionUser}
                        </Select>
                    </Form.Item>
                )}
                <Form.Item style={{ justifySelf: 'flex-end', marginBottom: '10px', marginTop: '10px' }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ justifySelf: 'flex-end', marginBottom: '10px', marginRight: '10px' }}
                        loading={controller.downloadIsLoading}
                        disabled={controller.showDownloadButton}
                    >
                        {translate({ key: 'general.generate' })}
                    </Button>
                    {controller.showDownloadButton && (
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ justifySelf: 'flex-start', marginBottom: '10px' }}
                            loading={controller.downloadIsLoading}
                        >
                            <CSVLink
                                data={controller.toggleDownload ? controller.newDownloadData : controller.downloadData}
                                headers={
                                    controller.toggleDownload
                                        ? controller.newDownloadDataHeaders
                                        : controller.downloadDataHeaders
                                }
                                filename={controller.downloadFilename}
                            >
                                {translate({ key: 'general.download' })}
                            </CSVLink>
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </div>
    );
};
