import { useEffect, useState } from 'react';
import {
    MassiveRfidCardsFormController,
    MiniSiteCardsTableRow,
    SelectedCardDateRange,
} from 'fragments/massive-rfid-cards-form/interfaces';
import { LockDto, SearchLocksInterface } from 'services/locks/locks.service';
import { useForm } from 'antd/lib/form/Form';
import { useAPILocks } from 'services/locks/api-locks.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { OrderTable, PaginationRequest } from 'global/interfaces';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import dayjs from 'dayjs';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useMassiveRfidCardsFormContext } from './context/massive-rfid-cards-form.context';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
    CreateRfidCardInput,
    CreateRfidCardRelation,
    RfidCardsListInput,
    SearchRfidCardsInterface,
    SiteRfidCardDto,
} from 'services/rfidcards/rfidcards.service';
import { useAPIRfidcard } from 'services/rfidcards/api-rfidcards.service';

const defaultSearch: SearchRfidCardsInterface = {
    search: '',
    since: '',
    until: '',
    card: [],
    label: [],
    devices: [],
    authorizer: [],
    status: [],
};

export const useMassiveRfidCardsFormController = (
    rfidCardService = useAPIRfidcard(),
    locksService = useAPILocks(),
    messenger = useMessenger(),
    { translate } = useTranslator(),
): /* <--Dependency Injections  like services hooks */
MassiveRfidCardsFormController => {
    /* State */
    const [selectedCardsKeys, setSelectedCardsKeys] = useState<React.Key[]>([]);
    const [selectedLocksKeys, setSelectedLocksKeys] = useState<React.Key[]>([]);
    const [selectedLocks, setSelectedLocks] = useState<{ id: string; value: string }[]>([]);
    const [cardsTags, setCardsTags] = useState<string[]>([]);
    const [locksTags, setLocksTags] = useState<string[]>([]);
    const [formatedDates, setFormatedDates] = useState<SelectedCardDateRange>({
        sinceDate: '1970-01-01T00:00:00Z',
        untilDate: '1970-01-01T23:00:00Z',
    });
    const [selectedDateRange, setSelectedDateRange] = useState<SelectedCardDateRange>({
        sinceDate: '1970-01-01T00:00:00Z',
        untilDate: '1970-01-01T23:00:00Z',
    });
    const [permanentChecked, setPermanentChecked] = useState<boolean>(false);

    const [rfidCards, setRfidCards] = useState<SiteRfidCardDto[]>([]);
    const [isLoadingCards, setIsLoadingCards] = useState<boolean>(false);
    const [searchCards, setSearchCards] = useState<string>('');
    const [cardsPage, setCardsPage] = useState<number>(0);
    const [cardsPageSize] = useState<number>(10);
    const [cardsTotal, setCardsTotal] = useState<number>(0);

    const [locks, setLocks] = useState<LockDto[]>([]);
    const [isLoadingLocks, setIsLoadingLocks] = useState<boolean>(false);
    const [searchLocks, setSearchLocks] = useState<string>('');
    const [locksPage, setLocksPage] = useState<number>(0);
    const [locksPageSize] = useState<number>(10);
    const [locksTotal, setLocksTotal] = useState<number>(0);

    const [form] = useForm();

    const {
        setSelectedCard,
        setIsEditModalVisible,
        setIsUserReassignmentModalVisible,
        sendAndSubscribe,
        isCreationFormLoading,
        setisCreationFormLoading,
    } = useMassiveRfidCardsFormContext();

    const [session, , , ,] = useLocalSession();
    const { selectedSite } = session;

    /* Listeners */
    useEffect(() => {
        if (!selectedSite) return;
        initialValues();
    }, [selectedSite]);

    useEffect(() => {
        const dates: SelectedCardDateRange = {
            sinceDate: '',
            untilDate: '',
        };
        if (selectedDateRange.sinceDate) dates.sinceDate = dayjs.utc(selectedDateRange.sinceDate).format('DD/MM/YYYY');
        if (selectedDateRange.untilDate) dates.untilDate = dayjs.utc(selectedDateRange.untilDate).format('DD/MM/YYYY');
        setFormatedDates(dates);
    }, [selectedDateRange]);

    /* View Events */
    const onEditButtonPressed = (card: string) => {
        setSelectedCard(rfidCards.find((rc) => rc.card_number === card));
        setIsEditModalVisible(true);
    };
    const onSwitchUserButtonPressed = (card: string) => {
        setSelectedCard(rfidCards.find((rc) => rc.card_number === card));
        setIsUserReassignmentModalVisible(true);
    };

    const onCreateButtonPressed = () => {
        setisCreationFormLoading(true);
        const relations: CreateRfidCardRelation[] = [];
        selectedLocksKeys.map((device) => {
            selectedCardsKeys.map((card) => {
                relations.push({ device_id: device.toString(), card_id: card.toString() });
            });
        });

        const creationSummary: CreateRfidCardInput = {
            relations,
            type_id: permanentChecked ? 3 : 1,
            nbf_date: !permanentChecked ? selectedDateRange.sinceDate : formatDate('1970-01-01', '00:00:00'),
            exp_date: !permanentChecked ? selectedDateRange.untilDate : formatDate('1970-01-01', '23:59:00'),
        };
        sendAndSubscribe(creationSummary);
    };

    const onSelectCardsChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedCardsKeys(newSelectedRowKeys);
        setCardsTags(
            newSelectedRowKeys.map((k) => rfidCards.find((rc) => rc.id === k.toString())?.label || k.toString()),
        );
    };
    const onSelectLocksChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedLocksKeys(newSelectedRowKeys);
        const newSelectedLocks: { id: string; value: string }[] = [];
        newSelectedRowKeys.map((key) => {
            const lock = selectedLocks.find((lock) => lock.id === key.toString());
            if (lock) newSelectedLocks.push(lock);
            else {
                const lock = locks.find((l) => l.id === key.toString());
                if (lock) newSelectedLocks.push({ id: lock.id, value: lock.name });
            }
        });
        setSelectedLocks(newSelectedLocks);
        setLocksTags(newSelectedLocks.map((selectedLock) => selectedLock.value));
    };

    const loadMoreLocks = () => {
        setIsLoadingLocks(true);
        const pagination = {
            page: locksPage + 1,
            pageSize: locksPageSize,
        };
        setLocksPage(locksPage + 1);
        const orderTable: OrderTable = {
            order: undefined,
            orderBy: undefined,
        };
        const search: SearchLocksInterface = {
            searchInput: searchLocks,
            lockStatus: [1],
            lockType: [5],
        };
        getLocks(pagination, orderTable, search);
    };
    const loadMoreCards = () => {
        setIsLoadingCards(true);
        const pagination = {
            page: cardsPage + 1,
            pageSize: cardsPageSize,
        };
        setCardsPage(cardsPage + 1);
        const orderTable: OrderTable = {
            order: 'ascend',
            orderBy: 'label',
        };
        const search: SearchRfidCardsInterface = {
            ...defaultSearch,
            search: searchCards,
        };
        getRfidCards({ pagination, orderTable, search });
    };

    const onSearchLocks = (value: string) => {
        setIsLoadingLocks(true);
        const pagination = {
            page: 0,
            pageSize: locksPageSize,
        };
        setLocksPage(0);
        const orderTable: OrderTable = {
            order: undefined,
            orderBy: undefined,
        };
        const search: SearchLocksInterface = {
            searchInput: value,
            lockStatus: [1],
            lockType: [5],
        };
        getLocks(pagination, orderTable, search, { initial: true });
    };
    const onSearchCards = (value: string) => {
        setIsLoadingCards(true);
        const pagination = {
            page: 0,
            pageSize: cardsPageSize,
        };
        setCardsPage(0);
        const orderTable: OrderTable = {
            order: 'ascend',
            orderBy: 'label',
        };
        const search: SearchRfidCardsInterface = {
            ...defaultSearch,
            search: value,
        };
        getRfidCards({ pagination, orderTable, search }, { initial: true });
    };
    const onLoadCards = (value: boolean) => {
        setIsLoadingCards(value);
    };
    const onLoadLocks = (value: boolean) => {
        setIsLoadingLocks(value);
    };

    const onPickedDateRange = (range: [moment.Moment | null, moment.Moment | null]) => {
        const dateRange = {
            sinceDate: range[0] ? formatDate(range[0].utc().format('YYYY-MM-DD'), '00:00:00') : '',
            untilDate: range[1] ? formatDate(range[1].utc().format('YYYY-MM-DD'), '23:59:00') : '',
        };

        setSelectedDateRange(dateRange);
    };
    const onCheckPermanentChange = (e: CheckboxChangeEvent) => {
        setPermanentChecked(e.target.checked);
    };

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        if (!current) return true;
        const moreThanOneYear =
            !!selectedDateRange.sinceDate.length && current.isAfter(moment(selectedDateRange.sinceDate).add(1, 'year'));
        const isBeforeToday = !!current && current < moment().startOf('day');
        return isBeforeToday || moreThanOneYear;
    };
    const reloadForm = () => {
        initialValues();
    };

    /* Private Methods */
    const getLocks = (
        pagination?: PaginationRequest,
        order?: OrderTable,
        search?: SearchLocksInterface,
        options?: { initial: boolean },
    ) => {
        setIsLoadingLocks(true);
        locksService
            .listLocksInCardCreation(pagination, order, search)
            .then((data) => {
                const currLocks = options?.initial ? [] : locks;
                setLocks([...currLocks, ...data.locks]);
                setLocksTotal(data.total);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'lock.list-error' });
                console.log('get-locks-error', error);
            })
            .finally(() => {
                setIsLoadingLocks(false);
            });
    };

    const getRfidCards = (payload: RfidCardsListInput, options?: { initial: boolean }) => {
        setIsLoadingCards(true);
        rfidCardService
            .listValidSiteRfidCards(payload)
            .then((data) => {
                const cards = options?.initial ? [] : rfidCards;
                setRfidCards([...cards, ...data.rfid_cards]);
                setCardsTotal(data.total);
            })
            .catch(() => {
                messenger.showErrorMessage({ key: 'cards.list-error' });
            })
            .finally(() => {
                setIsLoadingCards(false);
            });
    };
    const initialValues = () => {
        resetFields();
        const pagination = {
            page: 0,
            pageSize: cardsPageSize,
        };
        const orderTable: OrderTable = {
            order: 'ascend',
            orderBy: 'label',
        };
        const searchLocks: SearchLocksInterface = {
            searchInput: '',
            lockStatus: [1],
            lockType: [5],
        };
        getRfidCards({ pagination, orderTable, search: defaultSearch }, { initial: true });
        getLocks(pagination, { order: undefined, orderBy: undefined }, searchLocks, { initial: true });
    };
    const resetFields = () => {
        setRfidCards([]);
        setLocks([]);
        setSelectedCardsKeys([]);
        setSelectedLocksKeys([]);
        setCardsPage(0);
        setLocksPage(0);
        setCardsTotal(0);
        setLocksTotal(0);
        setSearchCards('');
        setSearchLocks('');
        setSelectedDateRange({
            sinceDate: '',
            untilDate: '',
        });
        setFormatedDates({
            sinceDate: '',
            untilDate: '',
        });
        setPermanentChecked(false);
        setCardsTags([]);
        setLocksTags([]);
        form.resetFields();
    };
    const formatDate = (date: string, time: string) => {
        return `${date}T${time}Z`;
    };
    const mapDtoToTableRow = (dto: SiteRfidCardDto): MiniSiteCardsTableRow => {
        const devicesArr = (JSON.parse(dto.devices) || []) as string[];
        const viewModelObject = {
            id: dto.id,
            label: dto.label,
            card_number: dto.card_number,
            devices: {
                multiple: devicesArr.length > 1,
                text: devicesArr
                    .map((ap, i) => {
                        if (i === devicesArr.length - 1) return ap;
                        if (i === devicesArr.length - 2) return ap + ' ' + translate({ key: 'general.and' }) + ' ';
                        return ap + ', ';
                    })
                    .join(''),
            },
            device_count: dto.device_count,
        };
        return viewModelObject;
    };

    // Return state and events
    return {
        isCreationFormLoading,
        isLoadingCards,
        isLoadingLocks,
        form,
        locks,
        rfidCards: rfidCards.map(mapDtoToTableRow),
        cardsTags,
        locksTags,
        selectedCardsKeys,
        selectedLocksKeys,
        cardsTotal,
        locksTotal,
        selectedDateRange,
        formatedDates,
        permanentChecked,
        onEditButtonPressed,
        onSwitchUserButtonPressed,
        onCreateButtonPressed,
        onSelectCardsChange,
        onSelectLocksChange,
        loadMoreCards,
        loadMoreLocks,
        onSearchCards,
        onSearchLocks,
        onLoadCards,
        onLoadLocks,
        onPickedDateRange,
        onCheckPermanentChange,
        disabledDate,
        reloadForm,
    };
};
