import { SorterResult } from 'antd/lib/table/interface';
import { ITag, SorterInfo } from 'global/interfaces';
import React, { useContext } from 'react';
import { AccessReportDto } from 'services/access-reports/access-reports.service';
import { LockDto } from 'services/locks/locks.service';
import { GetAccessReportsInterface } from './access-reports-provider.component';

export interface AccessReportsContextInterface {
    isFilterModalVisible: boolean;
    accessReports: AccessReportDto[];
    currentPage: number;
    totalReports?: number;
    pageSize: number;
    searchInput: string;
    isTableLoading: boolean;
    locks: LockDto[];
    isLoadingLocks: boolean;
    selectedLockIds: string[];
    dateRange: { from: string; to: string };
    toggleTable: boolean;
    orderBy: string | undefined;
    order: undefined | 'ascend' | 'descend';
    sortedInfo: SorterResult<SorterInfo>;
    tags: ITag[];
    selectedAccessMethodIds: number[];
    selectedReportStateIds: number[];
    setSelectedAccessMethodIds: React.Dispatch<React.SetStateAction<number[]>>;
    setSelectedReportStateIds: React.Dispatch<React.SetStateAction<number[]>>;
    setTags: React.Dispatch<React.SetStateAction<ITag[]>>;
    setOrder: React.Dispatch<React.SetStateAction<undefined | 'ascend' | 'descend'>>;
    setOrderBy: React.Dispatch<React.SetStateAction<string | undefined>>;
    setSortedInfo: React.Dispatch<React.SetStateAction<SorterResult<SorterInfo>>>;
    setToggleTable: React.Dispatch<React.SetStateAction<boolean>>;
    setDateRange: React.Dispatch<React.SetStateAction<{ from: string; to: string }>>;
    setSelectedLockIds: React.Dispatch<React.SetStateAction<string[]>>;
    setLocks: React.Dispatch<React.SetStateAction<LockDto[]>>;
    setIsLoadingLocks: React.Dispatch<React.SetStateAction<boolean>>;
    setIsFilterModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setSearchInput: React.Dispatch<React.SetStateAction<string>>;
    setAccessReports: React.Dispatch<React.SetStateAction<AccessReportDto[]>>;
    setTotalReports: React.Dispatch<React.SetStateAction<number | undefined>>;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
    setIsTableLoading: React.Dispatch<React.SetStateAction<boolean>>;
    //________________Functions___________
    getAccessReports: (payload: GetAccessReportsInterface) => void;
    getLocks: () => void;
}

export const AccessReportsContext = React.createContext<AccessReportsContextInterface>({
    isFilterModalVisible: false,
    accessReports: [],
    currentPage: 0,
    pageSize: 20,
    isTableLoading: false,
    locks: [],
    searchInput: '',
    selectedLockIds: [],
    dateRange: { from: '', to: '' },
    toggleTable: false,
    orderBy: undefined,
    order: undefined,
    sortedInfo: { order: undefined, columnKey: undefined },
    isLoadingLocks: false,
    tags: [],
    selectedAccessMethodIds: [],
    selectedReportStateIds: [],

    setSelectedAccessMethodIds: () => {
        /* Nothing to do here*/
    },
    setSelectedReportStateIds: () => {
        /* Nothing to do here*/
    },
    setTags: () => {
        /* Nothing to do here*/
    },
    setIsLoadingLocks: () => {
        /* Nothing to do here*/
    },
    setSortedInfo: () => {
        /* Nothing to do here*/
    },
    setOrder: () => {
        /* Nothing to do here*/
    },
    setOrderBy: () => {
        /* Nothing to do here*/
    },
    setToggleTable: () => {
        /* Nothing to do here*/
    },
    setDateRange: () => {
        /* Nothing to do here*/
    },
    setSelectedLockIds: () => {
        /* Nothing to do here*/
    },
    setLocks: () => {
        /* Nothing to do here*/
    },
    setIsFilterModalVisible: () => {
        /* Nothing to do here*/
    },
    setTotalReports: () => {
        /* Nothing to do here*/
    },
    setAccessReports: () => {
        /* Nothing to do here*/
    },
    setCurrentPage: () => {
        /* Nothing to do here*/
    },
    setPageSize: () => {
        /* Nothing to do here*/
    },
    setSearchInput: () => {
        /* Nothing to do here*/
    },
    setIsTableLoading: () => {
        /* Nothing to do here*/
    },
    //__________Functions__________
    getAccessReports: () => {
        /* Nothing to do here*/
    },
    getLocks: () => {
        /* Nothing to do here*/
    },
});

export const useAccessReportsContext = (): AccessReportsContextInterface => {
    const store = useContext(AccessReportsContext);
    return store;
};
