import React from 'react';
import 'fragments/site-policies/site-policies.scss';
import { Button, Col, List, Modal, Row, Space, Table, Tabs, Tag, Tooltip, Typography } from 'antd';
import { SitePoliciesFragmentProps, SitePolicyTableRow } from 'fragments/site-policies/interfaces';
import { useSitePoliciesController } from 'fragments/site-policies/site-policies.controller';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { CreateSitePolicyFragment } from './fragments/create-site-policy/create-site-policy.fragment';

import FilterOutlinedIcon from 'assets/icons/filter-outlined-icon';
import {
    DeleteFilled,
    EditOutlined,
    DownloadOutlined,
    CheckSquareOutlined,
    EyeFilled,
    SyncOutlined,
    UserAddOutlined,
    ColumnHeightOutlined,
    ExclamationCircleOutlined,
    CloseCircleOutlined,
    CheckCircleOutlined,
    LoadingOutlined,
    PlusOutlined,
    ClockCircleOutlined,
} from '@ant-design/icons';
import KeyBtIcon from 'assets/icons/key-bt-icon';
import { SitePolicyActionListFragment } from './fragments/site-policy-action-list/site-policy-action-list.fragment';
import { FilterSitePoliciesModalFragment } from './fragments/filter-site-policies-modal/filter-site-policies-modal.fragment';
import { ViewRedeemersModalFragment } from './fragments/view-redeemers-modal/view-redeemers-modal.fragment';
import InfinityIcon from 'assets/icons/infinity-icon';
import { SitePolicyControlFragment } from './fragments/site-policy-control/site-policy-control.fragment';
import { EditSitePolicyFragment } from './fragments/edit-site-policy/edit-site-policy.fragment';
import { SitePolicyHistoryFragment } from './fragments/site-policy-history/site-policy-history.fragment';

const { Text } = Typography;
const { confirm } = Modal;

export const SitePoliciesFragment: React.FC<SitePoliciesFragmentProps> = (props) => {
    const { useController = useSitePoliciesController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    const showConfirmDeleteModal = (id: string) => {
        confirm({
            title: <Text strong>{translate({ key: 'general.sure-to-delete' })}</Text>,
            icon: <ExclamationCircleOutlined />,
            cancelButtonProps: { className: 'btn-cancel' },
            okButtonProps: { className: 'delete' },
            content: translate({ key: 'general.one-way-action' }),
            okText: translate({ key: 'general.remove' }),
            cancelText: translate({ key: 'general.cancel' }),
            onOk() {
                controller.onConfirmDeleteButtonPressed(id);
            },
            onCancel() {
                () => {
                    console.log();
                };
            },
        });
    };
    const showConfirmDownloadAllSitePoliciesModal = () => {
        confirm({
            title: <Text strong>{translate({ key: 'site-policies.download.modal-title' })}</Text>,
            icon: <DownloadOutlined />,
            cancelButtonProps: { className: 'btn-cancel' },
            okButtonProps: { className: 'delete' },
            content: (
                <Text>
                    {translate({ key: 'site-policies.download.modal-message-all-policies' })}
                    <Text strong> valido</Text>
                </Text>
            ),
            okText: translate({ key: 'general.download' }),
            cancelText: translate({ key: 'general.cancel' }),
            onOk() {
                controller.onDownloadAllButtonPressed();
            },
            onCancel() {
                () => {
                    console.log();
                };
            },
        });
    };
    const showConfirmDownloadSelectedPoliciesModal = () => {
        confirm({
            title: <Text strong>{translate({ key: 'site-policies.download.modal-title' })}</Text>,
            icon: <DownloadOutlined />,
            cancelButtonProps: { className: 'btn-cancel' },
            okButtonProps: { className: 'delete' },
            content: (
                <div>
                    <Text>
                        {translate({ key: 'site-policies.download.modal-message-selected-policies' })}
                        <Text strong> valido</Text>
                    </Text>
                    <List
                        className="download-policies-list"
                        style={{
                            marginTop: '20px',
                            marginRight: '20px',
                            border: '1px solid #f2f2f2',
                            borderRadius: '5px',
                            padding: '10px',
                            paddingTop: '0px',
                            maxHeight: '300px',
                            overflowY: 'auto',
                        }}
                        header={
                            <Text strong style={{ paddingTop: '0' }}>
                                {translate({ key: 'site-policies.download.policies-to-download' })}:
                            </Text>
                        }
                        dataSource={controller.selectedPolicyNamesForDownload}
                        renderItem={(item) => (
                            <List.Item key={item} style={{ padding: 0 }}>
                                <List.Item.Meta title={item} style={{ padding: '2px' }} />
                            </List.Item>
                        )}
                    />
                </div>
            ),
            okText: translate({ key: 'general.download' }),
            cancelText: translate({ key: 'general.cancel' }),
            onOk() {
                controller.onDownloadSelectedPoliciesButtonPressed();
            },
            onCancel() {
                () => {
                    console.log();
                };
            },
        });
    };

    const getStatusIcon = (status: string) => {
        switch (status) {
            case translate({ key: 'site-policies.status-options.deleted' }):
                return <CloseCircleOutlined />;
            case translate({ key: 'site-policies.status-options.finished' }):
                return <CloseCircleOutlined />;
            case translate({ key: 'site-policies.status-options.expired' }):
                return <CloseCircleOutlined />;
            case translate({ key: 'site-policies.status-options.in-review' }):
                return <ClockCircleOutlined />;
            case translate({ key: 'site-policies.status-options.stand-by' }):
                return <SyncOutlined spin />;
            case translate({ key: 'site-policies.status-options.enabled' }):
                return <CheckCircleOutlined />;
            default:
                return <></>;
        }
    };

    // Render
    return (
        <div className={'site-policies'}>
            <div style={{ backgroundColor: '#fff', marginBottom: '1.5rem', padding: '1rem', width: '100%' }}>
                {controller.isSuperAdmin && (
                    <div style={{ gap: '10px', display: 'flex' }}>
                        <div
                            style={{
                                width: '100%',
                                backgroundColor: '#f2f2f2',
                                padding: '10px',
                            }}
                        >
                            <Text strong>{translate({ key: 'general.notes' })}: </Text>
                            <Text>{translate({ key: 'site-policies.notes' })}</Text>
                        </div>
                        <Tooltip title={translate({ key: 'site-policies.download.all' })}>
                            <Button
                                className={'button'}
                                type="default"
                                style={{ padding: '3px' }}
                                onClick={showConfirmDownloadAllSitePoliciesModal}
                                icon={controller.isLoadingDownloadAll ? <LoadingOutlined /> : <DownloadOutlined />}
                            />
                        </Tooltip>
                    </div>
                )}
                <div style={{ marginTop: '6px', padding: '10px', backgroundColor: '#f2f2f2' }}>
                    <Text strong>{translate({ key: 'site-policies.actions.glosary' })}:</Text>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <div>
                            <Text strong style={{ paddingLeft: '0.4rem' }}>
                                Acciones:
                            </Text>
                        </div>
                        <div>
                            <KeyBtIcon style={{ width: '15px', height: '15px', color: '#108ee9' }} />
                            <Text strong style={{ paddingLeft: '0.4rem' }}>
                                {translate({ key: 'site-policies.actions.generate-key' })}
                            </Text>
                        </div>
                        <div>
                            <SyncOutlined style={{ color: '#108ee9' }} />
                            <Text strong style={{ paddingLeft: '0.4rem' }}>
                                {translate({ key: 'site-policies.actions.reset-lock' })}
                            </Text>
                        </div>
                        <div>
                            <UserAddOutlined style={{ color: '#777' }} />
                            <Text strong style={{ paddingLeft: '0.4rem' }}>
                                {translate({ key: 'site-policies.actions.assign-role-basic' })}
                            </Text>
                        </div>
                        <div>
                            <UserAddOutlined style={{ color: '#D48806' }} />
                            <Text strong style={{ paddingLeft: '0.4rem' }}>
                                {translate({ key: 'site-policies.actions.assign-role-full' })}
                            </Text>
                        </div>
                    </div>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <div>
                            <Text strong style={{ paddingLeft: '0.4rem' }}>
                                Historial:
                            </Text>
                        </div>
                        <div>
                            <SyncOutlined spin style={{ color: '#7C5FBC' }} />
                            <Text strong style={{ paddingLeft: '0.4rem' }}>
                                {translate({ key: 'site-policies.status-options.stand-by' })}
                            </Text>
                        </div>
                        <div>
                            <ClockCircleOutlined style={{ color: '#08979C' }} />
                            <Text strong style={{ paddingLeft: '0.4rem' }}>
                                {translate({ key: 'site-policies.status-options.in-review' })}
                            </Text>
                        </div>
                        <div>
                            <CheckCircleOutlined style={{ color: '#52C41A' }} />
                            <Text strong style={{ paddingLeft: '0.4rem' }}>
                                {translate({ key: 'site-policies.status-options.enabled' })}
                            </Text>
                        </div>
                        <div>
                            <CloseCircleOutlined style={{ color: '#FF4D4F' }} />
                            <Text strong style={{ paddingLeft: '0.4rem' }}>
                                {translate({ key: 'site-policies.status-options.deleted' })}
                            </Text>
                        </div>
                    </div>
                </div>
            </div>

            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#fff',
                    padding: '10px',
                    gap: '20px',
                }}
            >
                <div style={{ width: '100%' }}>
                    <FilterSitePoliciesModalFragment />
                </div>
                <Space>
                    <Tooltip
                        title={translate({
                            key: controller.expandAll
                                ? 'site-policies.expand-rows.collect'
                                : 'site-policies.expand-rows.expand',
                        })}
                    >
                        <Button
                            className={!controller.totalSitePolicies ? '' : 'button'}
                            type="default"
                            disabled={!controller.totalSitePolicies}
                            style={{ padding: '3px' }}
                            onClick={controller.onExpandAllRowsButtonPressed}
                            icon={<ColumnHeightOutlined />}
                        />
                    </Tooltip>
                    <Tooltip title={translate({ key: 'site-policies.download.selected' })}>
                        <Button
                            className={
                                !controller.totalSitePolicies || controller.selectedPolicyIdsForDownload.length === 0
                                    ? ''
                                    : 'button'
                            }
                            type="default"
                            disabled={
                                !controller.totalSitePolicies || controller.selectedPolicyIdsForDownload.length === 0
                            }
                            style={{ padding: '3px' }}
                            onClick={showConfirmDownloadSelectedPoliciesModal}
                            icon={controller.isLoadingDownloadSelected ? <LoadingOutlined /> : <DownloadOutlined />}
                        />
                    </Tooltip>
                    <Tooltip title={translate({ key: 'general.filters' })}>
                        <Button
                            className="button"
                            type="default"
                            style={{ padding: '3px' }}
                            onClick={controller.onFilterButtonPressed}
                            icon={<FilterOutlinedIcon />}
                        />
                    </Tooltip>
                    <Tooltip title={translate({ key: 'general.add' })}>
                        <Button
                            className="button"
                            type="default"
                            style={{ padding: '3px' }}
                            onClick={controller.onAddSitePolicyButtonPressed}
                            icon={<PlusOutlined />}
                        />
                    </Tooltip>
                </Space>
            </div>
            <Table
                columns={[
                    {
                        key: 'name',
                        title: translate({ key: 'label.name' }),
                        dataIndex: 'name',
                        sorter: !!controller.totalSitePolicies,
                        sortOrder: controller.sortedInfo.columnKey === 'name' ? controller.sortedInfo.order : null,
                    },
                    {
                        key: 'status',
                        title: translate({ key: 'label.state' }),
                        dataIndex: 'status',
                        sorter: !!controller.totalSitePolicies,
                        sortOrder: controller.sortedInfo.columnKey === 'status' ? controller.sortedInfo.order : null,
                        render: (status) => (
                            <Tag color={status.color} icon={getStatusIcon(status.label)} style={{ fontWeight: 'bold' }}>
                                {status.label}
                            </Tag>
                        ),
                    },
                    {
                        key: 'count',
                        title: translate({ key: 'site-policies.redeems.redeems' }),
                        dataIndex: 'count',
                        render: (count, record) => (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '6px',
                                    minWidth: '60px',
                                }}
                            >
                                <Tooltip
                                    placement="topRight"
                                    title={`${count.redeems} canjes usados de ${count.redeem_total} disponibles`}
                                >
                                    {count.redeem_total === 0 ? (
                                        <>
                                            <Text
                                                strong
                                                style={{
                                                    color: count.redeems == 0 ? 'green' : 'red',
                                                }}
                                            >
                                                {`${count.redeems}/`}
                                            </Text>
                                            <InfinityIcon
                                                style={{
                                                    width: '16px',
                                                    height: '16px',
                                                    color: '#262626d9',
                                                    marginLeft: '-6px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <Text
                                            strong
                                            style={{
                                                color: count.redeems == 0 ? 'green' : 'red',
                                            }}
                                        >
                                            {`${count.redeems}/${count.redeem_total}`}
                                        </Text>
                                    )}
                                </Tooltip>
                                <Tooltip
                                    placement="topLeft"
                                    title={translate({ key: 'site-policies.redeems.view-all-redeems' })}
                                >
                                    <Button
                                        disabled={count.redeems === 0}
                                        style={{ border: 'none', background: 'transparent' }}
                                        icon={<EyeFilled />}
                                        onClick={() => controller.onViewRedeemsButtonPressed(record.id)}
                                    />
                                </Tooltip>
                            </div>
                        ),
                    },
                    {
                        key: 'last_redeemer',
                        title: translate({ key: 'site-policies.redeems.last-redeemer' }),
                        dataIndex: 'last_redeemer',
                    },
                    {
                        key: 'created_at',
                        title: translate({ key: 'label.created-at' }),
                        dataIndex: 'created_at',
                        sorter: !!controller.totalSitePolicies,
                        sortOrder:
                            controller.sortedInfo.columnKey === 'created_at' ? controller.sortedInfo.order : null,
                        render: (created_at) => (
                            <Tag color="default" style={{ fontWeight: 'bold' }}>
                                {created_at}
                            </Tag>
                        ),
                    },
                    {
                        key: 'expires_at',
                        title: translate({ key: 'label.expires-at' }),
                        dataIndex: 'expires_at',
                        sorter: !!controller.totalSitePolicies,
                        sortOrder:
                            controller.sortedInfo.columnKey === 'expires_at' ? controller.sortedInfo.order : null,
                        render: (expires_at) => (
                            <Tag color="default" style={{ fontWeight: 'bold' }}>
                                {expires_at}
                            </Tag>
                        ),
                    },
                    {
                        key: 'action',
                        title: translate({ key: 'label.actions' }),
                        render: (_, { id, status }) => (
                            <div style={{ display: 'flex', gap: '.5rem' }}>
                                <Button
                                    disabled={status.id === 4 || status.id === 3 || status.id === 5}
                                    danger
                                    icon={
                                        controller.deletePolicyId === id && controller.isLoadingDelete ? (
                                            <LoadingOutlined />
                                        ) : (
                                            <DeleteFilled />
                                        )
                                    }
                                    onClick={() => showConfirmDeleteModal(id)}
                                />
                                <Button
                                    disabled={
                                        status.id !== 1 ||
                                        (controller.downloadPolicyId !== id && controller.isLoadingDownloadOne)
                                    }
                                    icon={
                                        controller.downloadPolicyId === id && controller.isLoadingDownloadOne ? (
                                            <LoadingOutlined />
                                        ) : (
                                            <DownloadOutlined />
                                        )
                                    }
                                    onClick={() => controller.onDownloadOneButtonPressed(id)}
                                />
                                {status.id == 0 && (
                                    <Button
                                        // disabled={status.id !== 0}
                                        icon={
                                            controller.isLoadingValidate ? <LoadingOutlined /> : <CheckSquareOutlined />
                                        }
                                        onClick={() => controller.onOpenControlModal(id)}
                                    />
                                )}
                                {status.id == 2 && (
                                    <Button
                                        disabled={status.id !== 2}
                                        icon={controller.isLoadingValidate ? <LoadingOutlined /> : <EditOutlined />}
                                        onClick={() => controller.onOpenEditModal(id)}
                                    />
                                )}
                            </div>
                        ),
                    },
                ]}
                dataSource={controller.itemsViewModel}
                pagination={{
                    current: controller.currentPage + 1,
                    showSizeChanger: true,
                    pageSize: controller.pageSize,
                    total: controller.totalSitePolicies,
                    pageSizeOptions: ['10', '20', '30', '40'],
                }}
                onChange={controller.handleTableChange}
                loading={controller.isTableLoading}
                rowKey={(itemsViewModel: SitePolicyTableRow) => itemsViewModel.id}
                rowSelection={{ ...controller.rowSelection, preserveSelectedRowKeys: false, fixed: true }}
                expandable={{
                    // defaultExpandAllRows: controller.expandAll,
                    expandedRowKeys: controller.expandedRowKeys,
                    onExpand: (expanded, record) => {
                        controller.onExpandOneRowButtonPressed(record.id, expanded);
                    },
                    expandedRowRender: (record) => {
                        return (
                            <Tabs destroyInactiveTabPane={true}>
                                <Tabs.TabPane tab="Acciones" key="1">
                                    <SitePolicyActionListFragment policyId={record.id} />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Historial" key="2">
                                    <SitePolicyHistoryFragment policyId={record.id} />
                                </Tabs.TabPane>
                            </Tabs>
                        );
                    },
                }}
            />

            <CreateSitePolicyFragment />
            <ViewRedeemersModalFragment policyId={controller.selectedPolicyIdForViewRedeemers} />
            <SitePolicyControlFragment policyId={controller.controlPolicyId} />
            <EditSitePolicyFragment policyId={controller.editPolicyId} />
            {/* <ConfirmKeyFragment /> */}
        </div>
    );
};
