import { useEffect, useState } from 'react';
import { FilterModalAccessReportsController } from 'fragments/access-reports-dashboard/fragments/filter-modal-access-reports/interfaces';
import { useAccessReportsContext } from 'fragments/access-reports-dashboard/context/access-reports.context';
import { useForm } from 'antd/lib/form/Form';
import dayjs from 'dayjs';
import moment from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker';
import { SearchAccessReportsInterface } from 'services/access-reports/access-reports.service';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { CheckboxOption, ITag } from 'global/interfaces';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

export const useFilterModalAccessReportsController = (
    { translate } = useTranslator(),
): /* <--Dependency Injections  like services hooks */
FilterModalAccessReportsController => {
    /* State */
    const [isLoading, setIsLoading] = useState(false);
    const [accessMethodOptions, setAccessMethodOptions] = useState<CheckboxOption[]>([]);
    const [checkAllAccessMethod, setCheckAllAccessMethod] = useState(false);
    const [indeterminate, setIndeterminate] = useState(true);
    const [reportStateOptions, setReportStateOptions] = useState<CheckboxOption[]>([]);
    const [tempAccessMethodIds, setTempAccessMethodIds] = useState<number[]>([]);
    const [tempReportStateIds, setTempReportStateIds] = useState<number[]>([]);
    const [tempLockIds, setTempLockIds] = useState<string[]>([]);
    const [tempDateRange, setTempDateRange] = useState<{ from: string; to: string }>({ from: '', to: '' });
    const [tempSearchInput, setTempSearchInput] = useState<string>('');
    const {
        selectedLockIds,
        searchInput,
        dateRange,
        pageSize,
        isFilterModalVisible,
        locks,
        tags,
        setTags,
        setIsFilterModalVisible,
        setSelectedLockIds,
        setSearchInput,
        setDateRange,
        isLoadingLocks,
        isTableLoading,
        getAccessReports,
        accessReports,
        toggleTable,
        selectedAccessMethodIds,
        selectedReportStateIds,
        setSelectedAccessMethodIds,
        setSelectedReportStateIds,
    } = useAccessReportsContext();
    const [form] = useForm();

    const [session] = useLocalSession();
    const { selectedSite } = session;

    /* Listeners */

    useEffect(() => {
        if (session.token && selectedSite) {
            setDefaultAccessMethodOptions();
            setDefaultReportStateOptions();
        }
    }, [selectedSite]);

    //________________TAGS________________
    useEffect(() => {
        mapTags({
            locks: selectedLockIds,
            startDate: dateRange.from,
            endDate: dateRange.to,
            searchInput,
            accessMethodIds: selectedAccessMethodIds,
            reportStateIds: selectedReportStateIds,
        });
        setTempLockIds(selectedLockIds);
        setTempSearchInput(searchInput);
        setTempDateRange(dateRange);
        setTempAccessMethodIds(selectedAccessMethodIds);
        setTempReportStateIds(selectedReportStateIds);
    }, [selectedLockIds, dateRange, searchInput, locks, selectedReportStateIds, selectedAccessMethodIds]);

    useEffect(() => {
        form.resetFields();
    }, [accessReports]);

    /* View Events */

    //________________button events________________

    const onSubmitButtonPressed = () => {
        setSearchInput(tempSearchInput);
        setDateRange(tempDateRange);
        setSelectedLockIds(tempLockIds);
        setSelectedAccessMethodIds(tempAccessMethodIds);
        setSelectedReportStateIds(tempReportStateIds);
        getAccessReports({
            pagination: { page: 0, pageSize },
            search: {
                startDate: tempDateRange.from,
                endDate: tempDateRange.to,
                searchInput: tempSearchInput,
                locks: tempLockIds,
                accessMethodIds: tempAccessMethodIds,
                reportStateIds: tempReportStateIds,
            },
        });
        setIsFilterModalVisible(false);
    };

    const onCancelButtonPressed = () => {
        setTempLockIds(selectedLockIds);
        setTempSearchInput(searchInput);
        setTempDateRange(dateRange);
        setTempAccessMethodIds(selectedAccessMethodIds);
        setTempReportStateIds(selectedReportStateIds);
        setIndeterminate(
            !!selectedAccessMethodIds.length && selectedAccessMethodIds.length < defaultAccessMethodOptions.length,
        );
        setIsFilterModalVisible(false);
        form.resetFields();
    };

    const onResetButtonPressed = () => {
        form.resetFields();
        setDateRange({ from: '', to: '' });
        setSelectedLockIds([]);
        setDefaultAccessMethodOptions();
        setDefaultReportStateOptions();
        setSearchInput('');
        getAccessReports({
            pagination: { page: 0, pageSize },
            orderTable: { order: undefined, orderBy: undefined },
            search: {
                startDate: '',
                endDate: '',
                searchInput: '',
                locks: [],
                accessMethodIds: [],
                reportStateIds: [],
            },
        });
        setIsFilterModalVisible(false);
    };

    //________________input events________________

    const onPickedDate = (date: any) => {
        if (date) {
            setTempDateRange({ from: dayjs(date[0]).format('YYYY-MM-DD'), to: dayjs(date[1]).format('YYYY-MM-DD') });
        } else {
            setTempDateRange({ from: '', to: '' });
        }
    };

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        return current && current > moment().endOf('day');
    };

    const onLockSelect = (value: string[]) => {
        setTempLockIds(value);
    };

    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTempSearchInput(e.target.value);
    };
    const onCheckAccessMethodOption = (list: CheckboxValueType[]) => {
        setTempAccessMethodIds(list as number[]);
        setIndeterminate(!!list.length && list.length < accessMethodOptions.length);
        setCheckAllAccessMethod(list.length === accessMethodOptions.length);
    };

    const onCheckAllAccessMethodOption = (e: CheckboxChangeEvent) => {
        setTempAccessMethodIds(e.target.checked ? accessMethodOptions.map((item) => item.value) : []);
        setIndeterminate(false);
        setCheckAllAccessMethod(e.target.checked);
    };

    const onCheckReportStateOption = (list: CheckboxValueType[]) => {
        setTempReportStateIds(list as number[]);
    };

    /* Private Methods */
    //________________TAGS________________

    const onCloseTag = (removedTag: ITag) => {
        switch (removedTag.name) {
            case translate({ key: 'label.lock' }): {
                const newSelectedLockIds = selectedLockIds.filter((lockId) => lockId !== removedTag.id);
                setSelectedLockIds(newSelectedLockIds);
                getAccessReports({
                    pagination: { page: 0, pageSize },
                    search: {
                        startDate: dateRange.from,
                        endDate: dateRange.to,
                        searchInput,
                        locks: newSelectedLockIds,
                        accessMethodIds: selectedAccessMethodIds,
                        reportStateIds: selectedReportStateIds,
                    },
                });
                break;
            }
            case translate({ key: 'label.name-email' }): {
                setSearchInput('');
                getAccessReports({
                    pagination: { page: 0, pageSize },
                    search: {
                        startDate: dateRange.from,
                        endDate: dateRange.to,
                        searchInput: '',
                        locks: selectedLockIds,
                        accessMethodIds: selectedAccessMethodIds,
                        reportStateIds: selectedReportStateIds,
                    },
                });
                break;
            }
            case translate({ key: 'label.duration' }): {
                setDateRange({ from: '', to: '' });
                getAccessReports({
                    pagination: { page: 0, pageSize },
                    search: {
                        startDate: '',
                        endDate: '',
                        searchInput,
                        locks: selectedLockIds,
                        accessMethodIds: selectedAccessMethodIds,
                        reportStateIds: selectedReportStateIds,
                    },
                });
                break;
            }
            case translate({ key: 'label.access-method' }): {
                const newSelectedAccessMethodIds = selectedAccessMethodIds.filter((id) => id !== removedTag.id);
                setSelectedAccessMethodIds(newSelectedAccessMethodIds);
                getAccessReports({
                    pagination: { page: 0, pageSize },
                    search: {
                        startDate: dateRange.from,
                        endDate: dateRange.to,
                        searchInput,
                        locks: selectedLockIds,
                        accessMethodIds: newSelectedAccessMethodIds,
                        reportStateIds: selectedReportStateIds,
                    },
                });
                break;
            }
            case translate({ key: 'label.state' }): {
                const newSelectedReportStateIds = selectedReportStateIds.filter((id) => id !== removedTag.id);
                setSelectedReportStateIds(newSelectedReportStateIds);
                getAccessReports({
                    pagination: { page: 0, pageSize },
                    search: {
                        startDate: dateRange.from,
                        endDate: dateRange.to,
                        searchInput,
                        locks: selectedLockIds,
                        accessMethodIds: selectedAccessMethodIds,
                        reportStateIds: newSelectedReportStateIds,
                    },
                });
                break;
            }
            default:
                break;
        }
    };

    const mapTags = (search: SearchAccessReportsInterface) => {
        const { locks: selectedLockIds, searchInput, startDate, endDate, accessMethodIds, reportStateIds } = search;
        const tempLockTags: ITag[] = [];
        const tempSearchTags: ITag[] = [];
        const tempDateTags: ITag[] = [];
        const tempAccessMethodTags: ITag[] = [];
        const tempReportStatusTags: ITag[] = [];

        selectedLockIds.map((id) => {
            const selectedLock = locks.find((lock) => lock.id === id);
            tempLockTags.push({
                id,
                name: translate({ key: 'label.lock' }),
                value: `${selectedLock?.name}`,
                color: 'geekblue',
                closable: true,
            });
        });

        searchInput &&
            tempSearchTags.push({
                id: searchInput,
                name: translate({ key: 'label.name-email' }),
                value: searchInput,
                color: 'purple',
                closable: true,
            });

        if (startDate !== '' || endDate !== '') {
            tempDateTags.push({
                id: startDate + endDate,
                name: translate({ key: 'label.duration' }),
                value: `Desde: ${startDate} Hasta: ${endDate}`,
                color: 'cyan',
                closable: true,
            });
        }
        if (selectedAccessMethodIds.length !== accessMethodOptions.length && toggleTable) {
            accessMethodIds.map((value) => {
                const selectedAccessMethod = accessMethodOptions.find((option) => option.value === value);
                tempAccessMethodTags.push({
                    id: value,
                    name: translate({ key: 'label.access-method' }),
                    value: `${selectedAccessMethod?.label}`,
                    color: 'green',
                    closable: true,
                });
            });
            if (tempAccessMethodTags.length === 1) {
                tempAccessMethodTags[0].closable = false;
            }
        }
        if (selectedReportStateIds.length !== reportStateOptions.length && toggleTable) {
            reportStateIds.map((value) => {
                const selectedReportState = reportStateOptions.find((option) => option.value === value);
                tempReportStatusTags.push({
                    id: value,
                    name: translate({ key: 'label.state' }),
                    value: `${selectedReportState?.label}`,
                    color: 'lime',
                    closable: true,
                });
            });
            if (tempReportStatusTags.length === 1) {
                tempReportStatusTags[0].closable = false;
            }
        }
        setTags([
            ...tempLockTags,
            ...tempSearchTags,
            ...tempDateTags,
            ...tempAccessMethodTags,
            ...tempReportStatusTags,
        ]);
    };

    //___________Set Filter Options __________
    const defaultAccessMethodOptions: CheckboxOption[] = [
        { value: 1, label: translate({ key: 'access-reports.access-method-option.app' }) },
        { value: 4, label: translate({ key: 'access-reports.access-method-option.passcode' }) },
        { value: 7, label: translate({ key: 'access-reports.access-method-option.rfid' }) },
        { value: 8, label: translate({ key: 'access-reports.access-method-option.fingerprint' }) },
        // { value: 12, label: translate({ key: 'access-reports.access-method-option.remote-access' }) },
        { value: 46, label: translate({ key: 'access-reports.access-method-option.button' }) },
    ];
    const defaultReportStateOptions: CheckboxOption[] = [
        { value: 0, label: translate({ key: 'access-reports.report-state-option.error' }) },
        { value: 1, label: translate({ key: 'access-reports.report-state-option.success' }) },
    ];
    const setDefaultAccessMethodOptions = (): void => {
        const accessMethodIds: number[] = defaultAccessMethodOptions.map((item) => item.value);
        setAccessMethodOptions(defaultAccessMethodOptions);
        setTempAccessMethodIds(accessMethodIds);
        setSelectedAccessMethodIds(accessMethodIds);
        setCheckAllAccessMethod(true);
    };
    const setDefaultReportStateOptions = (): void => {
        const reportStateIds: number[] = defaultReportStateOptions.map((item) => item.value);
        setReportStateOptions(defaultReportStateOptions);
        setTempReportStateIds(reportStateIds);
        setSelectedReportStateIds(reportStateIds);
    };

    // Return state and events
    return {
        form,
        tags,
        isLoading,
        selectedLockIds,
        searchInput,
        dateRange,
        isLoadingLocks,
        isTableLoading,
        isFilterModalVisible,
        locks,
        toggleTable,
        accessMethodOptions,
        reportStateOptions,
        selectedAccessMethodIds,
        selectedReportStateIds,
        onSearchInputChange,
        onResetButtonPressed,
        disabledDate,
        onPickedDate,
        onLockSelect,
        onSubmitButtonPressed,
        onCancelButtonPressed,
        onCloseTag,
        onCheckAccessMethodOption,
        onCheckReportStateOption,
        tempAccessMethodIds,
        tempReportStateIds,
        onCheckAllAccessMethodOption,
        checkAllAccessMethod,
        indeterminate,
    };
};
