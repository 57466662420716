import React from 'react';
import 'fragments/rfid-cards-dashboard/rfid-cards-dashboard.scss';
import { RfidCardTableRow, RfidCardsDashboardFragmentProps } from 'fragments/rfid-cards-dashboard/interfaces';
import { Button, Col, Row, Table, Tag, Typography, Tooltip, Space } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import {
    CheckCircleOutlined,
    InfoCircleOutlined,
    CloseCircleOutlined,
    PlusOutlined,
    DeleteFilled,
    LoadingOutlined,
} from '@ant-design/icons';

import { KeyScheduleOption } from 'services/keys/keys.service';
import FilterOutlinedIcon from 'assets/icons/filter-outlined-icon';
import { useNavigate } from 'react-router-dom';
import { useRfidCardsDashboardController } from './rfid-cards-dashboard.controller';
import { RfidCardsFilterModalFragment } from './fragments/rfid-cards-filter-modal/rfid-cards-filter-modal.fragment';
import { DeleteRfidCardModalFragment } from './fragments/delete-rfid-card-modal/delete-rfid-card-modal.fragment';
const { Text } = Typography;

export const RfidCardsDashboardFragment: React.FC<RfidCardsDashboardFragmentProps> = (props) => {
    const { useController = useRfidCardsDashboardController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    const navigate = useNavigate();

    // Render
    return (
        <div className={'rfid-cards-dashboard'}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#fff',
                    padding: '10px',
                    gap: '10px',
                }}
            >
                <div style={{ width: '100%' }}>
                    <RfidCardsFilterModalFragment />
                </div>
                <Space>
                    <Tooltip title={translate({ key: 'general.filters' })}>
                        <Button
                            className={'button'}
                            type="default"
                            style={{ justifySelf: 'flex-end', padding: '3px' }}
                            onClick={controller.onFilterButtonPressed}
                            icon={<FilterOutlinedIcon />}
                        />
                    </Tooltip>
                    <Tooltip title={translate({ key: 'rfid-card.add-massive-description' })}>
                        <Button
                            className={'button'}
                            type="default"
                            style={{ justifySelf: 'flex-end', padding: '3px' }}
                            onClick={() => navigate('/massive-rfid-card-form')}
                            icon={<PlusOutlined />}
                        />
                    </Tooltip>
                </Space>
            </div>
            <Table
                columns={[
                    {
                        key: 'label',
                        title: translate({ key: 'label.label' }),
                        dataIndex: 'label',
                        sorter: !!controller.totalRfidCards,
                        sortOrder: controller.sortedInfo.columnKey === 'label' ? controller.sortedInfo.order : null,
                        render: (label) => (
                            <div style={{ display: 'flex', alignItems: 'start', gap: '6px', minWidth: '140px' }}>
                                {label}
                            </div>
                        ),
                    },
                    {
                        key: 'deviceName',
                        title: translate({ key: 'label.lock' }),
                        dataIndex: 'deviceName',
                        sorter: !!controller.totalRfidCards,
                        sortOrder:
                            controller.sortedInfo.columnKey === 'deviceName' ? controller.sortedInfo.order : null,
                        render: (deviceName) => (
                            <div style={{ display: 'flex', alignItems: 'start', gap: '6px', minWidth: '130px' }}>
                                {deviceName}
                            </div>
                        ),
                    },
                    {
                        key: 'cardNumber',
                        title: translate({ key: 'label.card-number' }),
                        dataIndex: 'cardNumber',
                        sorter: !!controller.totalRfidCards,
                        sortOrder:
                            controller.sortedInfo.columnKey === 'cardNumber' ? controller.sortedInfo.order : null,
                        render: (cardNumber) => (
                            <div style={{ display: 'flex', alignItems: 'start', gap: '6px', minWidth: '130px' }}>
                                {cardNumber}
                            </div>
                        ),
                    },
                    {
                        key: 'validity',
                        title: translate({ key: 'label.duration' }),
                        dataIndex: 'validity',
                        render: (validity, { cardTypeId }) => (
                            <div>
                                {cardTypeId === 3 && (
                                    <Tag color="default" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        {translate({ key: 'label.permanent' })}
                                    </Tag>
                                )}
                                {cardTypeId !== 3 && (
                                    <span>
                                        {validity.from == validity.to ? (
                                            <Tag
                                                color="default"
                                                style={{
                                                    fontWeight: 'bold',
                                                    marginBottom: '4px',
                                                }}
                                            >
                                                {`${validity.from}`}
                                            </Tag>
                                        ) : (
                                            <>
                                                <Tag
                                                    color="default"
                                                    style={{
                                                        fontWeight: 'bold',
                                                        marginBottom: '4px',
                                                    }}
                                                >
                                                    {`Del: ${validity.from}`}
                                                </Tag>
                                                <Tag color="default" style={{ fontWeight: 'bold' }}>
                                                    {`Al:  ${validity.to}`}
                                                </Tag>
                                            </>
                                        )}
                                    </span>
                                )}
                            </div>
                        ),
                    },
                    {
                        key: 'dow',
                        title: translate({ key: 'label.availability' }),
                        dataIndex: 'dow',
                        render: (keySchedules, { time, cardTypeId }) => (
                            <div
                                style={{
                                    minHeight: '50px',
                                    minWidth: '160px',
                                    maxWidth: '300px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                }}
                            >
                                {(cardTypeId === 1 || cardTypeId === 3) && (
                                    <Tag color="default" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        {`Fulltime`}
                                    </Tag>
                                )}
                                {cardTypeId === 2 && (
                                    <>
                                        <Text>{time}</Text>
                                        <Row justify="space-between" style={{ marginTop: '5px' }}>
                                            {keySchedules.map((keyOption: KeyScheduleOption) => {
                                                return (
                                                    <Col xs={3} key={keyOption.key}>
                                                        <Tag
                                                            color={keyOption.selected ? 'success' : 'error'}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                minWidth: '100%',
                                                                fontWeight: 'bold',
                                                            }}
                                                            key={keyOption.key}
                                                        >
                                                            {keyOption.label.charAt(0)}
                                                        </Tag>
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </>
                                )}
                            </div>
                        ),
                    },
                    {
                        key: 'state',
                        title: translate({ key: 'label.state' }),
                        dataIndex: 'state',
                        sorter: !!controller.totalRfidCards,
                        sortOrder: controller.sortedInfo.columnKey === 'state' ? controller.sortedInfo.order : null,
                        render: (state, { validity }) => (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Tooltip placement="topLeft" title={validity.tooltip}>
                                    {state.id === 1 ? (
                                        <InfoCircleOutlined style={{ fontSize: '1.5rem', color: '#1890FF' }} />
                                    ) : state.id === 2 ? (
                                        <CheckCircleOutlined style={{ fontSize: '1.5rem', color: '#52C41A' }} />
                                    ) : state.id == 6 ? (
                                        <LoadingOutlined style={{ fontSize: '1.5rem', color: '#faad14' }} />
                                    ) : (
                                        (state.id == 3 || state.id == 4 || state.id == 5) && (
                                            <CloseCircleOutlined style={{ fontSize: '1.5rem', color: '#CF1322' }} />
                                        )
                                    )}
                                </Tooltip>
                            </div>
                        ),
                    },
                    {
                        key: 'action',
                        title: translate({ key: 'label.actions' }),
                        render: (_, { id, deleteDisabled, deviceId }) => (
                            <Button
                                disabled={deleteDisabled}
                                danger
                                icon={<DeleteFilled />}
                                onClick={() => controller.onOnDeleteRfidCardButtonPressed(id, deviceId)}
                            />
                        ),
                    },
                ]}
                dataSource={controller.itemsViewModelTable}
                pagination={{
                    current: controller.currentPage + 1,
                    showSizeChanger: true,
                    pageSize: controller.pageSize,
                    total: controller.totalRfidCards,
                    defaultPageSize: controller.pageSize,
                    pageSizeOptions: [20, 30, 50],
                }}
                onChange={controller.handleTableChange}
                loading={controller.isLoadingTable}
                rowKey={(itemsViewModelTable: RfidCardTableRow) => itemsViewModelTable.id}
                expandable={{
                    expandedRowRender: (card) => {
                        return (
                            <div>
                                <Row style={{ gap: '2rem' }}>
                                    <Col>
                                        <Text strong>{translate({ key: 'label.responsible' })}:</Text>
                                        <Text>
                                            {' '}
                                            {card.authorizerName} - {card.authorizerEmail}
                                        </Text>
                                    </Col>

                                    <Col>
                                        <Text strong>{translate({ key: 'label.creation-date' })}:</Text>
                                        <Text> {card.createdAt}</Text>
                                    </Col>
                                </Row>
                                <Row style={{ gap: '2rem' }}>
                                    <Col>
                                        <Text strong>{translate({ key: 'label.updated-by' })}:</Text>
                                        <Text>
                                            {' '}
                                            {card.updatedByName} - {card.updatedByEmail}
                                        </Text>
                                    </Col>
                                    <Col>
                                        <Text strong>{translate({ key: 'label.last-update' })}:</Text>
                                        <Text> {card.updatedAt}</Text>
                                    </Col>
                                </Row>
                                <Row style={{ gap: '2rem' }}>
                                    <Col>
                                        <Text strong>{translate({ key: 'label.state' })}: </Text>
                                        {card.state.id === 1 ? (
                                            <InfoCircleOutlined style={{ fontSize: '1.5rem', color: '#1890FF' }} />
                                        ) : card.state.id === 2 ? (
                                            <CheckCircleOutlined style={{ fontSize: '1.5rem', color: '#52C41A' }} />
                                        ) : card.state.id == 6 ? (
                                            <LoadingOutlined style={{ fontSize: '1.5rem', color: '#faad14' }} />
                                        ) : (
                                            (card.state.id == 3 || card.state.id == 4 || card.state.id == 5) && (
                                                <CloseCircleOutlined style={{ fontSize: '1.5rem', color: '#CF1322' }} />
                                            )
                                        )}
                                        <Text> {card.state.label}</Text>
                                    </Col>
                                </Row>
                            </div>
                        );
                    },
                }}
            />
            <DeleteRfidCardModalFragment />
        </div>
    );
};
