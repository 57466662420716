import React, { useState } from 'react';
import { ReactNode } from 'react';
import 'fragments/admins-dashboard/admins-dashboard.scss';
import { AdminsDashboardFragmentProps, AdminsTableRow } from 'fragments/admins-dashboard/interfaces';
import { useAdminsDashboardController } from 'fragments/admins-dashboard/admins-dashboard.controller';
import { CreateAdminModalFragment } from './fragments/create-admin-modal/create-admin-modal.fragment';
import { EditAdminModalFragment } from './fragments/edit-admin-modal/edit-admin-modal.fragment';
import { Button, Col, Row, Space, Table, Tag, Tooltip, Typography } from 'antd';
import { AdminDto } from 'services/admin/admin.service';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { AdminsFilterModalFragment } from './fragments/admins-filter-modal/admins-filter-modal.fragment';
import { UserAddOutlined } from '@ant-design/icons';
import FilterOutlinedIcon from 'assets/icons/filter-outlined-icon';
const { Text } = Typography;

export const AdminsDashboardFragment: React.FC<AdminsDashboardFragmentProps> = (props) => {
    const { useController = useAdminsDashboardController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'admins-dashboard'}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#fff',
                    padding: '10px',
                    gap: '20px',
                }}
            >
                <div style={{ width: '100%' }}>
                    <AdminsFilterModalFragment />
                </div>
                <Space>
                    <Tooltip title={translate({ key: 'general.filters' })}>
                        <Button
                            className={'button'}
                            type="default"
                            style={{ justifySelf: 'flex-end', padding: '3px' }}
                            onClick={controller.onFilterButtonPressed}
                            icon={<FilterOutlinedIcon />}
                        />
                    </Tooltip>
                    <Tooltip title={translate({ key: 'Nuevo Administrador' })}>
                        <Button
                            className={'button '}
                            type="default"
                            style={{ justifySelf: 'flex-end' }}
                            onClick={controller.onAddAdminButtonPressed}
                            icon={<UserAddOutlined style={{ fontSize: '1.2rem' }} />}
                        />
                    </Tooltip>
                </Space>
            </div>
            <Table
                columns={[
                    {
                        key: 'userName',
                        title: translate({ key: 'label.name' }),
                        dataIndex: 'userName',
                        sorter: !!controller.totalAdmins,
                        sortOrder: controller.sortedInfo.columnKey === 'userName' ? controller.sortedInfo.order : null,
                    },
                    {
                        key: 'lockName',
                        title: translate({ key: 'label.lock' }),
                        dataIndex: 'lockName',
                        sorter: !!controller.totalAdmins,
                        sortOrder: controller.sortedInfo.columnKey === 'lockName' ? controller.sortedInfo.order : null,
                    },
                    {
                        key: 'role',
                        title: translate({ key: 'label.role' }),
                        dataIndex: 'role',
                        sorter: !!controller.totalAdmins,
                        sortOrder: controller.sortedInfo.columnKey === 'role' ? controller.sortedInfo.order : null,
                    },
                    {
                        key: 'action',
                        title: translate({ key: 'label.actions' }),
                        render: (record: AdminDto): ReactNode => {
                            return (
                                <Button onClick={() => controller.onDeleteRole(record.id)}>
                                    {translate({ key: 'general.remove' })}
                                </Button>
                            );
                        },
                    },
                ]}
                rowKey={(itemsViewModelTable: AdminsTableRow) => itemsViewModelTable.id}
                dataSource={controller.itemsViewModelTable}
                pagination={{
                    current: controller.currentPage + 1,
                    showSizeChanger: true,
                    pageSize: controller.pageSize,
                    total: controller.totalAdmins,
                    pageSizeOptions: ['10', '15', '20', '30'],
                }}
                loading={controller.isTableLoading}
                onChange={controller.handleTableChange}
                expandable={{
                    expandedRowRender: (record) => {
                        return (
                            <div>
                                <Row style={{ gap: '2rem' }}>
                                    <Col>
                                        <Text strong>{translate({ key: 'label.authorizer' })}:</Text>
                                        <Text>
                                            {` ${record.authorizer}${
                                                record.authorizerEmail && ` - ${record.authorizerEmail}`
                                            }`}
                                        </Text>
                                    </Col>

                                    <Col>
                                        <Text strong>{translate({ key: 'label.date' })}:</Text>
                                        <Text> {record.createdAt}</Text>
                                    </Col>
                                </Row>
                                <Row style={{ gap: '2rem' }}>
                                    <Col>
                                        <Text strong>{translate({ key: 'label.admin' })}:</Text>
                                        <Text>{` ${record.userName} - ${record.userEmail}`}</Text>
                                    </Col>
                                </Row>
                            </div>
                        );
                    },
                }}
            />
            <CreateAdminModalFragment />
            <EditAdminModalFragment />
        </div>
    );
};
