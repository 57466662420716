import { useEffect, useState } from 'react';
import { DownloadReportsController, OptionMonth } from 'fragments/download-reports/interfaces';
import { useForm } from 'antd/lib/form/Form';
import dayjs from 'dayjs';
import {
    DownloadAccessReportDto,
    NewAccessReportDto,
    NewDownloadAccessReportDto,
    UserListDto,
} from 'services/access-reports/access-reports.service';
import { RadioChangeEvent } from 'antd';
import { hardcodeSiteId, useAPIAccessReports } from 'services/access-reports/api-access-reports.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { CheckboxOption } from 'global/interfaces';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

export const useDownloadReportsController = (
    accessReportService = useAPIAccessReports(),
    messenger = useMessenger(),
    { translate } = useTranslator(),
): /* <--Dependency Injections  like services hooks */
DownloadReportsController => {
    /* State */
    const [users, setUsers] = useState<UserListDto[]>([]);
    const [optionMonth, setOptionMonth] = useState<OptionMonth[]>([]);
    const [selectedMonth, setSelectedMonth] = useState<OptionMonth>({ label: '', since: '', until: '' });
    const [selectedUser, setSelectedUser] = useState<string>('');
    const [downloadFilename, setDownloadFilename] = useState<string>('');
    const [downloadIsLoading, setDownloadIsLoading] = useState<boolean>(false);
    const [downloadData, setDownloadData] = useState<DownloadAccessReportDto[]>([]);
    const [newDownloadData, setNewDownloadData] = useState<NewDownloadAccessReportDto[]>([]);
    const [showDownloadButton, setShowDownloadButton] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [toggleDownload, setToggleDownload] = useState<boolean>(false);

    const [selectedAccessMethodIds, setSelectedAccessMethodIds] = useState<number[]>([]);
    const [accessMethodOptions, setAccessMethodOptions] = useState<CheckboxOption[]>([]);
    const [checkAllAccessMethod, setCheckAllAccessMethod] = useState(false);
    const [indeterminate, setIndeterminate] = useState<boolean>(true);
    const [showSelect, setShowSelect] = useState<boolean>(true);

    const monthOfYear: { key: number; label: string }[] = [
        { key: 0, label: translate({ key: 'general.january' }) },
        { key: 1, label: translate({ key: 'general.february' }) },
        { key: 2, label: translate({ key: 'general.march' }) },
        { key: 3, label: translate({ key: 'general.april' }) },
        { key: 4, label: translate({ key: 'general.may' }) },
        { key: 5, label: translate({ key: 'general.june' }) },
        { key: 6, label: translate({ key: 'general.july' }) },
        { key: 7, label: translate({ key: 'general.august' }) },
        { key: 8, label: translate({ key: 'general.september' }) },
        { key: 9, label: translate({ key: 'general.october' }) },
        { key: 10, label: translate({ key: 'general.november' }) },
        { key: 11, label: translate({ key: 'general.december' }) },
        { key: -1, label: translate({ key: 'general.december' }) },
        { key: -2, label: translate({ key: 'general.november' }) },
        { key: -3, label: translate({ key: 'general.october' }) },
        { key: -4, label: translate({ key: 'general.september' }) },
    ];

    const downloadDataHeaders = [
        { label: translate({ key: 'label.user' }), key: 'user_full_name' },
        { label: translate({ key: 'label.email' }), key: 'user_email' },
        { label: translate({ key: 'label.lock' }), key: 'lock' },
        { label: translate({ key: 'label.date' }), key: 'datetime' },
    ];
    const newDownloadDataHeaders = [
        { label: translate({ key: 'label.name-label' }), key: 'user_full_name' },
        { label: translate({ key: 'label.email-identifier' }), key: 'user_email' },
        { label: translate({ key: 'label.lock' }), key: 'lock' },
        { label: translate({ key: 'label.access-method' }), key: 'type' },
        { label: translate({ key: 'label.state' }), key: 'status' },
        { label: translate({ key: 'label.date' }), key: 'datetime' },
    ];

    const [form] = useForm();
    const [session] = useLocalSession();
    const { selectedSite } = session;

    /* Listeners */
    useEffect(() => {
        if (session.token && selectedSite) {
            setDefaultAccessMethodOptions();
            getUsers();
            if (!!hardcodeSiteId.find((id) => id === session.selectedSite?.id)) {
                setToggleDownload(true);
            } else {
                setToggleDownload(false);
            }
        }
        getMonthOptions();
        setShowDownloadButton(false);
        form.resetFields();
    }, [selectedSite]);

    /* View Events */
    const onSubmitButtonPressed = () => {
        setDownloadFilename(`${dayjs().format('YYYYMMDDHHmmss')}_reportes_accefy.csv`);
        toggleDownload
            ? downloadNewAccessReports(selectedUser, selectedMonth.since, selectedMonth.until, selectedAccessMethodIds)
            : downloadAccessReports(selectedUser, selectedMonth.since, selectedMonth.until);
    };
    const onMonthSelect = ({ target: { value } }: RadioChangeEvent) => {
        setSelectedMonth(optionMonth[value]);
        setShowDownloadButton(false);
    };
    const onUserSelect = (value: string) => {
        setShowDownloadButton(false);
        setSelectedUser(value);
    };
    const onCheckAccessMethodOption = (list: CheckboxValueType[]) => {
        setShowDownloadButton(false);
        setSelectedAccessMethodIds(list as number[]);
        setIndeterminate(!!list.length && list.length < accessMethodOptions.length);
        setCheckAllAccessMethod(list.length === accessMethodOptions.length);
        if (list.find((key) => key === 1)) {
            setShowSelect(true);
        } else {
            setShowSelect(false);
            setSelectedUser('');
            form.setFieldsValue({ user: undefined });
        }
    };
    const onCheckAllAccessMethodOption = (e: CheckboxChangeEvent) => {
        setSelectedAccessMethodIds(e.target.checked ? accessMethodOptions.map((item) => item.value) : []);
        setIndeterminate(false);
        setCheckAllAccessMethod(e.target.checked);
        setShowSelect(e.target.checked);
    };

    /* Private Methods */

    const defaultAccessMethodOptions: CheckboxOption[] = [
        { value: 1, label: translate({ key: 'access-reports.access-method-option.app' }) },
        { value: 4, label: translate({ key: 'access-reports.access-method-option.passcode' }) },
        { value: 7, label: translate({ key: 'access-reports.access-method-option.rfid' }) },
        // { value: 8, label: translate({ key: 'access-reports.access-method-option.fingerprint' }) },
        // { value: 12, label: translate({ key: 'access-reports.access-method-option.remote-access' }) },
        { value: 46, label: translate({ key: 'access-reports.access-method-option.button' }) },
    ];

    const setDefaultAccessMethodOptions = (): void => {
        const accessMethodIds: number[] = defaultAccessMethodOptions.map((item) => item.value);
        setAccessMethodOptions(defaultAccessMethodOptions);
        setSelectedAccessMethodIds(accessMethodIds);
        setCheckAllAccessMethod(true);
    };
    const getMonthOptions = () => {
        const currentMonth = dayjs().month();
        const options: OptionMonth[] = [];
        for (let i = 0; i < 3; i++) {
            options.unshift({
                label: monthOfYear.find((month) => month.key === currentMonth - i)?.label,
                since: dayjs()
                    .month(currentMonth - i)
                    .startOf('M')
                    .format('YYYY-MM-DD'),
                until: dayjs()
                    .month(currentMonth - i)
                    .endOf('M')
                    .format('YYYY-MM-DD'),
            });
        }
        setOptionMonth(options);
    };
    const getUsers = () => {
        setIsLoading(true);
        accessReportService
            .listUsers()
            .then((data) => {
                setUsers(data);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'access-reports.get-users-error' });
                console.log('get-users-error', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const downloadAccessReports = (userId: string, since: string, until: string) => {
        setDownloadIsLoading(true);
        accessReportService
            .downloadAccessReports(userId, since, until)
            .then((data) => {
                data = data.map((report) => {
                    return {
                        ...report,
                        datetime: `${report.datetime.substring(0, 10)} ${report.datetime.substring(11, 16)} (${
                            selectedSite?.time_zone
                        })`,
                    };
                });
                setDownloadData(data);
                setShowDownloadButton(true);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'access-reports.generate-report-error' });
                console.log('generate-report-error', error);
            })
            .finally(() => {
                setDownloadIsLoading(false);
            });
    };

    const reportTypeToString = (type: number): string => {
        switch (type) {
            case 1:
                return translate({ key: 'access-reports.access-method-option.app' });
            case 4:
                return translate({ key: 'access-reports.access-method-option.passcode' });
            case 7:
                return translate({ key: 'access-reports.access-method-option.rfid' });
            case 8:
                return translate({ key: 'access-reports.access-method-option.fingerprint' });
            case 12:
                return translate({ key: 'access-reports.access-method-option.remote-access' });
            case 46:
                return translate({ key: 'access-reports.access-method-option.button' });
            default:
                return translate({ key: 'general.unknown' });
        }
    };

    const reportStatusToString = (status: number): string => {
        switch (status) {
            case 0:
                return translate({ key: 'access-reports.report-state-option.error' });
            case 1:
                return translate({ key: 'access-reports.report-state-option.success' });
            default:
                return translate({ key: 'general.unknown' });
        }
    };

    const downloadNewAccessReports = (userId: string, since: string, until: string, reportType: number[]) => {
        setDownloadIsLoading(true);
        accessReportService
            .downloadNewAccessReports(userId, since, until, reportType)
            .then((data) => {
                const procesedData: NewDownloadAccessReportDto[] = data.map((report) => {
                    return {
                        user_full_name: report.full_name,
                        user_email: report.user_email,
                        lock: report.lock_name,
                        type: reportTypeToString(report.type),
                        status: reportStatusToString(report.success),
                        datetime: `${report.report_datetime.substring(0, 10)} ${report.report_datetime.substring(
                            11,
                            16,
                        )} (${selectedSite?.time_zone})`,
                    };
                });
                setNewDownloadData(procesedData);
                setShowDownloadButton(true);
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'access-reports.generate-report-error' });
                console.log('generate-report-error', error);
            })
            .finally(() => {
                setDownloadIsLoading(false);
            });
    };

    // Return state and events
    return {
        form,
        users,
        isLoading,
        optionMonth,
        selectedUser,
        monthOfYear,
        downloadData,
        downloadIsLoading,
        downloadDataHeaders,
        toggleDownload,
        newDownloadDataHeaders,
        newDownloadData,
        downloadFilename,
        showDownloadButton,
        selectedAccessMethodIds,
        accessMethodOptions,
        showSelect,
        indeterminate,
        checkAllAccessMethod,
        onSubmitButtonPressed,
        onMonthSelect,
        onUserSelect,
        onCheckAllAccessMethodOption,
        onCheckAccessMethodOption,
    };
};
