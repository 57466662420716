import React from 'react';
import 'fragments/sites-modal/sites-modal.scss';
import { Modal } from 'antd';
import { useMainContext } from 'fragments/context/main/main.context';
// import { useTranslator } from 'tools/view-hooks/translator-hook';
import { SitesModalFragmentProps } from 'fragments/sites-modal/interfaces';
import { SitesListFragment } from 'fragments/sites-modal/sites-list/sites-list.fragment';
import { useSitesContext } from 'fragments/sites-modal/context/sites.context';
import { CreateSiteFragment } from 'fragments/sites-modal/create-site/create-site.fragment';

export const SitesModalFragment: React.FC<SitesModalFragmentProps> = () => {
    // const { translate } = useTranslator();
    const { isAdminSitesModalVisible } = useMainContext();
    const { isCreateSiteFragmentVisible } = useSitesContext();

    // Render
    return (
        <Modal
            className={'sites-list-modal'}
            open={isAdminSitesModalVisible}
            bodyStyle={{ padding: '0 0 0px 0' }}
            closable={false}
            footer={false}
            centered
        >
            {isCreateSiteFragmentVisible ? <CreateSiteFragment /> : <SitesListFragment />}
        </Modal>
    );
};
