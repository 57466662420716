import React from 'react';
import 'fragments/admins-dashboard/fragments/create-admin-modal/create-admin-modal.scss';
import { CreateAdminModalFragmentProps } from 'fragments/admins-dashboard/fragments/create-admin-modal/interfaces';
import { useCreateAdminModalController } from 'fragments/admins-dashboard/fragments/create-admin-modal/create-admin-modal.controller';
import { Button, Select, Typography, Modal, Form } from 'antd';
import { useAdminContext } from 'fragments/admins-dashboard/context/admins.context';
import { useTranslator } from 'tools/view-hooks/translator-hook';
const { Option } = Select;
const { Text, Title } = Typography;

export const CreateAdminModalFragment: React.FC<CreateAdminModalFragmentProps> = (props) => {
    const { useController = useCreateAdminModalController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    const { isCreateAdminModalVisible } = useAdminContext();

    const optionLocks = controller.locks.map((lock) => (
        <Option key={lock.id} value={lock.id}>
            <div style={{ display: 'flex', justifyContent: 'space-between', textTransform: 'capitalize' }}>
                {lock.name}
            </div>
        </Option>
    ));

    // Render
    return (
        <Modal
            open={isCreateAdminModalVisible}
            destroyOnClose={true}
            title={
                <Title level={4} style={{ padding: 0 }}>
                    {translate({ key: 'admin.create-title' })}
                </Title>
            }
            className={'create-admin-modal'}
            closable={false}
            centered
            footer={[
                <Button key="back" className={'btn-cancel'} onClick={controller.onCancelButtonPressed}>
                    {translate({ key: 'general.cancel' })}
                </Button>,
                <Button
                    form="create-admin-form"
                    key="submit"
                    type="primary"
                    className={'button primary'}
                    loading={controller.isLoading}
                    htmlType="submit"
                >
                    {translate({ key: 'general.add' })}
                </Button>,
            ]}
        >
            <Form
                id="create-admin-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                size="middle"
                className={'form'}
                form={controller.form}
                onFinish={controller.onCreateAdminFormSubmit}
            >
                <Form.Item
                    label={
                        <Text type="secondary" strong>
                            {translate({ key: 'label.lock' })}
                        </Text>
                    }
                    name="lock_ids"
                    required
                    rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                >
                    <Select
                        placeholder={translate({ key: 'form.placeholder.lock.name' })}
                        mode="multiple"
                        allowClear
                        value={controller.selectedLockIds}
                        onChange={controller.onLockSelect}
                        style={{ width: '100%' }}
                        filterOption={(inputValue, option) => {
                            const opt = controller.locks.find((item) => item.id == option?.value);
                            return !!opt?.name.toLowerCase().includes(inputValue.toLowerCase());
                        }}
                    >
                        {optionLocks}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={
                        <Text type="secondary" strong>
                            {translate({ key: 'label.admin-email' })}
                        </Text>
                    }
                    name="admin_emails"
                    required
                    rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                >
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        allowClear
                        tokenSeparators={[' ']}
                        value={controller.selectedAdminEmails}
                        onChange={controller.onAdminEmailAdd}
                        placeholder={translate({ key: 'form.placeholder.admin.admin-email' })}
                        dropdownStyle={{ display: 'none' }}
                    />
                </Form.Item>

                <Form.Item
                    label={
                        <Text type="secondary" strong>
                            {translate({ key: 'label.role' })}
                        </Text>
                    }
                    name="role"
                    required
                    rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                >
                    <Select
                        style={{ width: '100%' }}
                        options={controller.roleOptions}
                        onChange={controller.onRoleSelected}
                        // placeholder={translate({ key: 'form.placeholder.admin.admin-email' })}
                        // dropdownStyle={{ display: 'none' }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};
