import React from 'react';
import 'fragments/keys-dashboard/keys-dashboard.scss';
import { KeysDashboardFragmentProps, KeysTableRow } from 'fragments/keys-dashboard/interfaces';
import { useKeysDashboardController } from 'fragments/keys-dashboard/keys-dashboard.controller';
import { Button, Col, Row, Table, Tag, Typography, Modal, Tooltip, Space } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import {
    DeleteFilled,
    ExclamationCircleOutlined,
    CheckCircleOutlined,
    InfoCircleOutlined,
    CloseCircleOutlined,
} from '@ant-design/icons';

import { KeyScheduleOption } from 'services/keys/keys.service';
import { KeysFilterModalFragment } from './fragments/keys-filter-modal/keys-filter-modal.fragment';
import { CreateKeyFragment } from 'fragments/common/create-key/create-key.fragment';
import FilterOutlinedIcon from 'assets/icons/filter-outlined-icon';
import AddKeyIcon from 'assets/icons/add-key-icon';
const { Text } = Typography;
const { confirm } = Modal;

export const KeysDashboardFragment: React.FC<KeysDashboardFragmentProps> = (props) => {
    const { useController = useKeysDashboardController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    const showConfirmModal = (id: string) => {
        confirm({
            title: translate({ key: 'general.sure-to-delete' }),
            icon: <ExclamationCircleOutlined />,
            cancelButtonProps: { className: 'btn-cancel' },
            okButtonProps: { className: 'delete' },
            content: translate({ key: 'general.one-way-action' }),
            okText: translate({ key: 'general.remove' }),
            cancelText: translate({ key: 'general.cancel' }),
            onOk() {
                controller.onConfirmDeleteKey(id);
            },
            onCancel() {
                () => {
                    console.log();
                };
            },
        });
    };
    // Render
    return (
        <div className={'keys-dashboard'}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#fff',
                    padding: '10px',
                    gap: '20px',
                }}
            >
                <div style={{ width: '100%' }}>
                    <KeysFilterModalFragment />
                </div>
                <Space>
                    <Tooltip title={translate({ key: 'general.filters' })}>
                        <Button
                            className={'button'}
                            type="default"
                            style={{ justifySelf: 'flex-end', padding: '3px' }}
                            onClick={controller.onFilterButtonPressed}
                            icon={<FilterOutlinedIcon />}
                        />
                    </Tooltip>
                    <Tooltip title={translate({ key: 'general.add' })}>
                        <Button
                            className={'button'}
                            type="default"
                            style={{ justifySelf: 'flex-end', padding: '3px' }}
                            onClick={controller.onAddKeyButtonPressed}
                            icon={<AddKeyIcon style={{ strokeWidth: '20' }} />}
                        />
                    </Tooltip>
                </Space>
            </div>
            <Table
                columns={[
                    {
                        key: 'lockName',
                        title: translate({ key: 'label.lock' }),
                        dataIndex: 'lockName',
                        sorter: !!controller.totalKeys,
                        sortOrder: controller.sortedInfo.columnKey === 'lockName' ? controller.sortedInfo.order : null,
                        render: (lockName, { lockStatusId }) => (
                            <div style={{ display: 'flex', alignItems: 'start', gap: '6px', minWidth: '140px' }}>
                                {!lockStatusId && (
                                    <Tooltip
                                        placement="topLeft"
                                        title={translate({ key: 'key.tooltips.lock-not-configured' })}
                                    >
                                        <ExclamationCircleOutlined style={{ color: '#faad14', marginTop: '4px' }} />
                                    </Tooltip>
                                )}
                                {lockName}
                            </div>
                        ),
                    },
                    {
                        key: 'userName',
                        title: translate({ key: 'label.name' }),
                        dataIndex: 'userName',
                        sorter: !!controller.totalKeys,
                        sortOrder: controller.sortedInfo.columnKey === 'userName' ? controller.sortedInfo.order : null,
                    },
                    {
                        key: 'validity',
                        title: translate({ key: 'label.duration' }),
                        dataIndex: 'validity',
                        render: (validity) => (
                            <div>
                                {validity.from == validity.to ? (
                                    <Tag
                                        color="default"
                                        style={{
                                            fontWeight: 'bold',
                                            marginBottom: '4px',
                                        }}
                                    >
                                        {`${validity.from}`}
                                    </Tag>
                                ) : (
                                    <>
                                        <Tag
                                            color="default"
                                            style={{
                                                fontWeight: 'bold',
                                                marginBottom: '4px',
                                            }}
                                        >
                                            {`Del: ${validity.from}`}
                                        </Tag>
                                        <Tag color="default" style={{ fontWeight: 'bold' }}>
                                            {`Al:  ${validity.to}`}
                                        </Tag>
                                    </>
                                )}
                            </div>
                        ),
                    },
                    {
                        key: 'dow',
                        title: translate({ key: 'label.availability' }),
                        dataIndex: 'dow',
                        render: (keySchedules, { time }) => (
                            <div style={{ minWidth: '160px', maxWidth: '300px' }}>
                                <Text>{time}</Text>
                                <Row justify="space-between" style={{ marginTop: '5px' }}>
                                    {keySchedules.map((keyOption: KeyScheduleOption) => {
                                        return (
                                            <Col xs={3} key={keyOption.key}>
                                                <Tag
                                                    color={keyOption.selected ? 'success' : 'error'}
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        minWidth: '100%',
                                                        fontWeight: 'bold',
                                                    }}
                                                    key={keyOption.key}
                                                >
                                                    {keyOption.label.charAt(0)}
                                                </Tag>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </div>
                        ),
                    },
                    {
                        key: 'state',
                        title: translate({ key: 'label.state' }),
                        dataIndex: 'state',
                        render: (state, { validity }) => (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Tooltip placement="topLeft" title={validity.tooltip}>
                                    {state.id === 2 ? (
                                        <CheckCircleOutlined style={{ fontSize: '1.5rem', color: '#52C41A' }} />
                                    ) : state.id === 1 ? (
                                        <InfoCircleOutlined style={{ fontSize: '1.5rem', color: '#1890FF' }} />
                                    ) : (
                                        <CloseCircleOutlined style={{ fontSize: '1.5rem', color: '#CF1322' }} />
                                    )}
                                </Tooltip>
                            </div>
                        ),
                    },
                    {
                        key: 'action',
                        title: translate({ key: 'label.actions' }),
                        render: (_, { id, deleteDisabled }) => (
                            <Button
                                disabled={deleteDisabled}
                                danger
                                icon={<DeleteFilled />}
                                onClick={() => showConfirmModal(id)}
                            />
                        ),
                    },
                ]}
                dataSource={controller.itemsViewModelTable}
                pagination={{
                    current: controller.currentPage + 1,
                    showSizeChanger: true,
                    pageSize: controller.pageSize,
                    total: controller.totalKeys,
                    pageSizeOptions: ['5', '10', '20'],
                }}
                onChange={controller.handleTableChange}
                loading={controller.isLoadingTable}
                rowKey={(itemsViewModelTable: KeysTableRow) => itemsViewModelTable.id}
                expandable={{
                    expandedRowRender: (record) => {
                        return (
                            <div>
                                <Row style={{ gap: '2rem' }}>
                                    <Col>
                                        <Text strong>{translate({ key: 'label.authorizer' })}:</Text>
                                        <Text>
                                            {` ${record.authorizer}${
                                                record.authorizerEmail && ` - ${record.authorizerEmail}`
                                            }`}
                                        </Text>
                                    </Col>

                                    <Col>
                                        <Text strong>{translate({ key: 'label.date' })}:</Text>
                                        <Text> {record.createdAt}</Text>
                                    </Col>
                                </Row>
                                <Row style={{ gap: '2rem' }}>
                                    <Col>
                                        <Text strong>{translate({ key: 'label.user' })}:</Text>
                                        <Text>{` ${record.userName} - ${record.userEmail}`}</Text>
                                    </Col>
                                    <Col>
                                        <Text strong>{translate({ key: 'label.state' })}: </Text>
                                        {record.state.id === 2 ? (
                                            <CheckCircleOutlined style={{ color: '#52C41A' }} />
                                        ) : record.state.id === 1 ? (
                                            <InfoCircleOutlined style={{ color: '#FAAD14' }} />
                                        ) : (
                                            <CloseCircleOutlined style={{ color: '#CF1322' }} />
                                        )}
                                        <Text>{` ${record.state.label} - ${record.validity.tooltip}`} </Text>
                                    </Col>
                                </Row>
                            </div>
                        );
                    },
                }}
            />
            <CreateKeyFragment
                onClose={controller.onCloseCreateKey}
                isModalVisible={controller.isCreateKeyModalVisible}
                onFinish={controller.onFinishKeyCreation}
            />
        </div>
    );
};
