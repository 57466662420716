import { useEffect, useState } from 'react';
import { CreatePasscodeModalController, SelectedDateRange } from 'fragments/common/create-passcode-modal/interfaces';
// import { usePasscodesContext } from 'fragments/passcodes/context/passcodes.context';
import { useForm } from 'antd/lib/form/Form';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { useAPIPasscodes } from 'services/passcodes/api-passcodes.service';
import moment from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker';
import { useLocalSession } from 'auth/helpers/session.hooks';
import dayjs from 'dayjs';
import { CheckPasscodeSameDate, CreatePasscodeResponse } from 'services/passcodes/passcodes.service';
import { formatDatetime } from 'tools/date-handling';
import { useAPILocks } from 'services/locks/api-locks.service';
import { LockDto } from 'services/locks/locks.service';

export const useCreatePasscodeModalController = (
    onClose: () => void,
    lockId?: string,
    onFinish?: () => void,
    passcodesService = useAPIPasscodes(),
    locksService = useAPILocks(),
    messenger = useMessenger(),
): /* <--Dependency Injections  like services hooks */
CreatePasscodeModalController => {
    /* State */
    const [isLoading, setIsLoading] = useState(false);
    const [isCheckDateLoading, setIsCheckDateLoading] = useState(false);
    const [isInvalidDateRange, setIsInvalidDateRange] = useState(false);
    const [selectedType, setSelectedType] = useState<number>(1);
    const [selectedLockId, setSelectedLockId] = useState<string>('');
    const [formatedDates, setFormatedDates] = useState<string[]>([]);
    const [selectedDateRange, setSelectedDateRange] = useState<SelectedDateRange>({
        sinceDate: '',
        untilDate: '',
        sinceTime: '',
        untilTime: '',
    });
    const [label, setLabel] = useState<string>('');
    const [createdPasscode, setCreatedPasscode] = useState<CreatePasscodeResponse>({
        since: '',
        until: '',
        lock_name: '',
        code: '',
        label: '',
        type: 1,
    });
    const [locks, setLocks] = useState<LockDto[]>([]);
    const [isLoadingLocks, setIsLoadingLocks] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<number>(0);

    const [form] = useForm();

    const [session] = useLocalSession();
    const { selectedSite } = session;

    /* Listeners */
    useEffect(() => {
        selectedSite && getLocks();
        setCurrentStep(0);
    }, [selectedSite]);

    useEffect(() => {
        const arr = [];
        if (selectedDateRange.sinceDate.length && selectedDateRange.sinceTime.length)
            arr.push(
                dayjs
                    .utc(formatDate(selectedDateRange.sinceDate, selectedDateRange.sinceTime))
                    .format('DD/MM/YYYY HH:00') + 'hs',
            );
        if (selectedDateRange.untilDate.length && selectedDateRange.untilTime.length)
            arr.push(
                dayjs
                    .utc(formatDate(selectedDateRange.untilDate, selectedDateRange.untilTime))
                    .format('DD/MM/YYYY HH:00') + 'hs',
            );
        setFormatedDates(arr);
    }, [selectedDateRange]);

    useEffect(() => {
        lockId && setSelectedLockId(lockId);
    }, [lockId]);
    /* View Events */
    //Ex. const onIncreaseButtonPressed = () => {}
    const onCreateButtonPressed = () => {
        console.log('Submit');
        setIsLoading(true);
        passcodesService
            .createPasscode({
                lock_id: selectedLockId,
                label,
                since: formatDate(selectedDateRange.sinceDate, selectedDateRange.sinceTime),
                until: formatDate(selectedDateRange.untilDate, selectedDateRange.untilTime),
                type: selectedType,
            })
            .then((r: CreatePasscodeResponse) => {
                setSelectedType(1);
                setSelectedDateRange({
                    sinceDate: '',
                    untilDate: '',
                    sinceTime: '',
                    untilTime: '',
                });
                setSelectedLockId('');
                setLabel('');
                setCreatedPasscode(r);
                setCurrentStep(1);
                form.resetFields();
            })
            .catch((error) => {
                if (error == 'label') {
                    setLabel('');
                    form.setFieldsValue({ code_label: undefined });
                    messenger.showErrorMessage({ key: 'passcodes.create-passcode-label-error' });
                } else {
                    messenger.showErrorMessage({ key: 'passcodes.create-passcode-error' });
                }
                console.log('create-passcode-error', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const onCancelButtonPressed = () => {
        setSelectedType(1);
        setSelectedLockId('');
        setSelectedDateRange({
            sinceDate: '',
            untilDate: '',
            sinceTime: '',
            untilTime: '',
        });
        setLabel('');
        setCurrentStep(0);
        setIsInvalidDateRange(false);
        form.resetFields();
        onClose();
    };

    const onCreateOtherPasscodeButtonPressed = () => {
        setCurrentStep(0);
        setCreatedPasscode({ since: '', until: '', code: '', lock_name: '', label: '', type: 1 });
        onFinish && onFinish();
    };

    const onCloseButtonPressed = () => {
        setCreatedPasscode({ since: '', until: '', code: '', lock_name: '', label: '', type: 1 });
        setCurrentStep(0);
        onFinish && onFinish();
        onClose();
    };

    const onLockSelect = (value: string) => {
        setSelectedLockId(value);
        selectedDateRange.sinceDate &&
            selectedDateRange.untilDate &&
            checkSameDate({
                since: formatDate(selectedDateRange.sinceDate, selectedDateRange.sinceTime),
                until: formatDate(selectedDateRange.untilDate, selectedDateRange.untilTime),
                lockId: value,
            });
    };

    const onLabelInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLabel(e.target.value);
    };

    const onPickedDate = (value: moment.Moment | null) => {
        if (value) {
            setSelectedDateRange({
                ...selectedDateRange,
                sinceDate: value.format('YYYY-MM-DD'),
            });
        }
    };
    const onPickedHour = (value: moment.Moment | null) => {
        if (value) {
            const hour = value.format('HH:00:00');
            setSelectedDateRange({
                ...selectedDateRange,
                sinceTime: hour,
                untilTime: value.add(6, 'hour').format('HH:00:00'),
                untilDate: dayjs.utc(formatDate(selectedDateRange.sinceDate, hour)).add(6, 'hour').format('YYYY-MM-DD'),
            });
        }
    };

    const onPickedDateRange = (range: [moment.Moment | null, moment.Moment | null]) => {
        if (range[0] && range[1]) {
            const dateRange = {
                sinceDate: range[0] ? range[0].format('YYYY-MM-DD') : '',
                untilDate: range[1] ? range[1].format('YYYY-MM-DD') : '',
                sinceTime: range[0] ? range[0].format('HH:00:00') : '',
                untilTime: range[1] ? range[1].format('HH:00:00') : '',
            };
            setSelectedDateRange(dateRange);
            selectedLockId &&
                checkSameDate({
                    since: formatDate(dateRange.sinceDate, dateRange.sinceTime),
                    until: formatDate(dateRange.untilDate, dateRange.untilTime),
                    lockId: selectedLockId,
                });
        }
    };

    const onTypeChange = (type: string) => {
        setSelectedType(Number(type));
        setSelectedLockId('');
        setSelectedDateRange({
            sinceDate: '',
            untilDate: '',
            sinceTime: '',
            untilTime: '',
        });
        setLabel('');
        form.resetFields();
    };

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        if (!current) return true;
        const moreThanOneYear =
            !!selectedDateRange.sinceDate.length &&
            !!selectedDateRange.sinceTime.length &&
            current.isAfter(moment(selectedDateRange.sinceDate).add(1, 'year'));
        const isBeforeToday = !!current && current < moment().startOf('day');
        return isBeforeToday || moreThanOneYear;
    };
    const disabledTime = (date: moment.Moment) => {
        return { disabledHours: () => disabledHour(date) };
    };

    const disabledHour = (date?: moment.Moment): number[] => {
        const currentHour = dayjs().get('hour');
        const disabledHoursArray: number[] = [];
        if (!date || dayjs().format('YYYY-MM-DD') === date.format('YYYY-MM-DD')) {
            for (let i = 0; i < currentHour; i++) {
                disabledHoursArray.push(i);
            }
        }
        return disabledHoursArray;
    };

    /* Private Methods */
    const checkSameDate = (input: CheckPasscodeSameDate) => {
        setIsCheckDateLoading(true);
        passcodesService
            .checkPasscodeSameDateExistsByLockId(input)
            .then((res) => {
                setIsInvalidDateRange(!res);
            })
            .catch((error) => {
                console.log('create-passcode-error', error);
            })
            .finally(() => {
                setIsCheckDateLoading(false);
            });
    };

    const getLocks = () => {
        setIsLoadingLocks(true);
        locksService
            .listLocksInKeyCreation()
            .then((data) => {
                setLocks(data.locks.filter((lock) => lock.lock_type_id === 5 && lock.lock_status_id === 1 && lock));
            })
            .catch((error) => {
                messenger.showErrorMessage({ key: 'lock.list-error' });
                console.log('get-locks-error', error);
            })
            .finally(() => {
                setIsLoadingLocks(false);
            });
    };

    const formatPasscode = (passcode: CreatePasscodeResponse): CreatePasscodeResponse => {
        return {
            ...passcode,
            since: formatDatetime(passcode.since, selectedSite?.time_zone),
            until: formatDatetime(passcode.until, selectedSite?.time_zone),
        };
    };
    const formatDate = (date: string, time: string) => {
        return `${date}T${time}Z`;
    };

    // Return state and events
    return {
        isLoading,
        form,
        locks,
        selectedLockId,
        selectedType,
        selectedDateRange,
        formatedDates,
        currentStep,
        isLoadingLocks,
        createdPasscode: formatPasscode(createdPasscode),
        isCheckDateLoading,
        isInvalidDateRange,
        onCloseButtonPressed,
        onCreateOtherPasscodeButtonPressed,
        disabledDate,
        disabledHour,
        disabledTime,
        onCreateButtonPressed,
        onCancelButtonPressed,
        onLockSelect,
        onPickedDate,
        onPickedDateRange,
        onTypeChange,
        onPickedHour,
        onLabelInputChange,
    };
};
