import {
    AccessReports,
    DownloadAccessReportDto,
    SearchAccessReportsInterface,
    PaginatedAccessReports,
    UserListDto,
    NewAccessReportDto,
} from 'services/access-reports/access-reports.service';
import axios from 'axios';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { OrderTable, PaginationRequest } from 'global/interfaces';

export const hardcodeSiteId: string[] = [
    '00cc4bc5-705a-42cf-aca2-35aacde45343', //accefy-arg prod
    '42d2286f-320a-41b8-b6ff-a21077ed7e2c', //accefy-arg testing
    '38ab80bf-7803-4467-a885-f7013e6031e9', // alpha inversiones prod
    '496e3f5f-2a12-43f2-9165-3e0000875fcb', // chez space prod
    '8921d36c-94da-4f72-9455-91c4eaf77893', // airsat
    '93bd40d8-59c5-4417-93bf-823cca80b7fa', // hospital neonatal
];

export const useAPIAccessReports = (): AccessReports => {
    const [session] = useLocalSession();
    const listAccessReports = async (
        pagination?: PaginationRequest,
        order?: OrderTable,
        search?: SearchAccessReportsInterface,
    ): Promise<PaginatedAccessReports> => {
        pagination = pagination || { page: 0, pageSize: 15 };
        order = order || { order: undefined, orderBy: undefined };
        search = search || {
            locks: [],
            startDate: '',
            endDate: '',
            searchInput: '',
            accessMethodIds: [],
            reportStateIds: [],
        };

        const locks = JSON.stringify(search.locks);
        const type = JSON.stringify(search.accessMethodIds);
        let success = '';

        if (search.reportStateIds.length === 1) {
            success = search.reportStateIds[0].toString();
        }

        const toggleRequest = !!hardcodeSiteId.find((id) => id === session.selectedSite?.id);
        const path = toggleRequest ? 'reportlocks' : 'reportapps';
        const queryParams = !toggleRequest
            ? `page=${pagination.page}&pageSize=${pagination.pageSize}&startDate=${search.startDate}&endDate=${search.endDate}&assignedTo=${search.searchInput}&locks=${locks}&order=${order.order}&orderBy=${order.orderBy}`
            : `page=${pagination.page}&pageSize=${pagination.pageSize}&startDate=${search.startDate}&endDate=${search.endDate}&searchTerm=${search.searchInput}&locks=${locks}&order=${order.order}&orderBy=${order.orderBy}&type=${type}&status=${success}`;

        const response = await axios.get(
            process.env.REACT_APP_API_BASE_URL + `/user/sites/${session.selectedSite?.id}/${path}?${queryParams}`,
            {
                headers: { Authorization: 'Bearer ' + session.token },
            },
        );

        return response.data as PaginatedAccessReports;
    };

    const listUsers = async (): Promise<UserListDto[]> => {
        const response = await axios.get(
            process.env.REACT_APP_API_BASE_URL + `/user/sites/${session.selectedSite?.id}/users`,
            {
                headers: { Authorization: 'Bearer ' + session.token },
            },
        );
        return response.data as UserListDto[];
    };

    const downloadAccessReports = async (
        userId: string,
        since: string,
        until: string,
    ): Promise<DownloadAccessReportDto[]> => {
        userId = userId === 'all' ? '' : userId;
        const response = await axios.get(
            process.env.REACT_APP_API_BASE_URL +
                `/user/sites/${session.selectedSite?.id}/reports/download?userId=${userId}&since=${since}&until=${until}`,
            {
                headers: { Authorization: 'Bearer ' + session.token },
            },
        );
        return response.data as DownloadAccessReportDto[];
    };
    const downloadNewAccessReports = async (
        userId: string,
        since: string,
        until: string,
        reportType: number[],
    ): Promise<NewAccessReportDto[]> => {
        userId = userId === 'all' ? '' : userId;
        const report = JSON.stringify(reportType);
        const response = await axios.get(
            process.env.REACT_APP_API_BASE_URL +
                `/user/sites/${session.selectedSite?.id}/reportlocks/download?userId=${userId}&reportType=${report}&since=${since}&until=${until}`,
            {
                headers: { Authorization: 'Bearer ' + session.token },
            },
        );
        return response.data as NewAccessReportDto[];
    };

    return {
        listAccessReports,
        downloadAccessReports,
        downloadNewAccessReports,
        listUsers,
    };
};
