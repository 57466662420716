import React, { useEffect, useState } from 'react';
import { Menu, Divider, Typography } from 'antd';
import { Link, useLocation, matchPath, useNavigate } from 'react-router-dom';
import {
    UnlockOutlined,
    ExportOutlined,
    UnorderedListOutlined,
    UserSwitchOutlined,
    ExperimentOutlined,
    DownloadOutlined,
    PieChartOutlined,
    PartitionOutlined,
} from '@ant-design/icons';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { Translator } from 'tools/view-hooks/translator-hook';
import KeyIcon from 'assets/icons/key-icon';
import PasscodeIcon from 'assets/icons/passcode-icon';
import RfidIcon from 'assets/icons/rfid-icon';
const { Text } = Typography;

export const NavigationMenu: React.FC<{ translate: Translator['translate']; controller: any }> = ({
    translate,
    controller,
}) => {
    const [currentKey, setCurrentKey] = useState<string>('');
    const [openSubMenus, setOpenSubMenu] = useState<string[]>([]);
    const location = useLocation();
    const navigate = useNavigate();
    // const matchSiteUrl: any = useRouteMatch('/site/:id');
    // const matchVisitorUrl: any = useRouteMatch('/visitor/:id');
    const matchSiteUrl: any = matchPath(location.pathname, '/site/:id');
    const matchVisitorUrl: any = matchPath(location.pathname, '/visitor/:id');

    //{/*  */}
    useEffect(() => {
        setActiveMenuKey(location.pathname);
    }, [location.pathname]);

    const setActiveMenuKey = (locationPath: string): void => {
        const isSiteDetails = matchSiteUrl?.url ? true : false;
        const isVisitorDetails = matchVisitorUrl?.url ? true : false;

        if (matchPath(location.pathname, '/rfidCards') || matchPath(location.pathname, '/record'))
            setOpenSubMenu(['rfidcardsmenu']);
        else setOpenSubMenu([]);

        if (isVisitorDetails) {
            return setCurrentKey('/visitors');
        }
        if (isSiteDetails) {
            return setCurrentKey('/sites');
        }
        return setCurrentKey(locationPath);
    };

    const [session] = useLocalSession();

    return (
        <Menu
            mode="inline"
            theme="light"
            selectedKeys={[currentKey]}
            openKeys={openSubMenus}
            onOpenChange={(openKeys) => {
                setOpenSubMenu(openKeys);
            }}
        >
            <Divider plain>
                <Text strong> {translate({ key: 'nav.reports' })} </Text>
            </Divider>
            <Menu.Item
                key={'/reports'}
                onClick={controller.onMenuItemClick}
                icon={<UnorderedListOutlined style={{ width: '16px', height: '16px' }} />}
            >
                <Link to={'/reports'}>{translate({ key: 'nav.display' })}</Link>
            </Menu.Item>
            <Menu.Item
                key={'/reports/download'}
                onClick={controller.onMenuItemClick}
                icon={<DownloadOutlined style={{ width: '16px', height: '16px' }} />}
            >
                <Link to={'/reports/download'}>{translate({ key: 'nav.download' })}</Link>
            </Menu.Item>
            <Divider plain>
                <Text strong> {translate({ key: 'nav.admin' })}</Text>
            </Divider>
            <Menu.Item
                key={'/locks'}
                onClick={controller.onMenuItemClick}
                icon={<UnlockOutlined style={{ width: '16px', height: '16px' }} />}
            >
                <Link to={'/locks'}>{translate({ key: 'nav.locks' })}</Link>
            </Menu.Item>
            <Menu.Item
                key={'/keys'}
                onClick={controller.onMenuItemClick}
                icon={<KeyIcon style={{ width: '16px', height: '16px' }} />}
            >
                <Link to={'/keys'}>{translate({ key: 'nav.keys' })}</Link>
            </Menu.Item>
            <Menu.Item
                key={'/passcodes'}
                onClick={controller.onMenuItemClick}
                icon={<PasscodeIcon style={{ width: '16px', height: '16px' }} />}
            >
                <Link to={'/passcodes'}>{translate({ key: 'nav.passcodes' })}</Link>
            </Menu.Item>
            <Menu.Item
                key={'/rfidcards'}
                onClick={controller.onMenuItemClick}
                icon={<RfidIcon style={{ width: '16px', height: '16px' }} />}
            >
                <Link to={'/rfidcards'}>{translate({ key: 'nav.cards' })}</Link>
            </Menu.Item>
            <Menu.Item
                key={'/admins'}
                onClick={controller.onMenuItemClick}
                icon={<UserSwitchOutlined style={{ width: '16px', height: '16px' }} />}
            >
                <Link to={'/admins'}>{translate({ key: 'nav.admins' })}</Link>
            </Menu.Item>
            {session.user_profile?.role === 'superadmin' && (
                <Menu.Item
                    key={'/operations'}
                    onClick={controller.onMenuItemClick}
                    icon={<PartitionOutlined style={{ width: '16px', height: '16px' }} />}
                >
                    <Link to={'/operations'}>{translate({ key: 'nav.operations' })}</Link>
                </Menu.Item>
            )}
            {session.user_profile?.role === 'superadmin' && (
                <Menu.Item
                    key={'/policies'}
                    onClick={controller.onMenuItemClick}
                    icon={<ExperimentOutlined style={{ width: '16px', height: '16px' }} />}
                >
                    <Link to={'/policies'}>{translate({ key: 'nav.policies' })}</Link>
                </Menu.Item>
            )}
            {session.user_profile?.role === 'superadmin' && (
                <Menu.Item
                    key={'/metrics'}
                    onClick={controller.onMenuItemClick}
                    icon={<PieChartOutlined style={{ width: '16px', height: '16px' }} />}
                >
                    <Link to={'/metrics'}>{translate({ key: 'nav.metrics' })}</Link>
                </Menu.Item>
            )}

            <Menu.Item
                key="8"
                onClick={controller.handleLogOut}
                icon={<ExportOutlined style={{ width: '16px', height: '16px' }} />}
            >
                {translate({ key: 'general.logout' })}
            </Menu.Item>
        </Menu>
    );
};
