import React from 'react';
import 'fragments/sites-modal/create-site/create-site.scss';
import { CreateSiteFragmentProps } from 'fragments/sites-modal/create-site/interfaces';
import { useCreateSiteController } from 'fragments/sites-modal/create-site/create-site.controller';
import { Button, Col, Form, Input, PageHeader, Row, Select, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
const { Option } = Select;
const { Text } = Typography;

export const CreateSiteFragment: React.FC<CreateSiteFragmentProps> = (props) => {
    const { useController = useCreateSiteController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    const optionTimeZones = controller.timeZones.map((timeZone) => (
        <Option key={timeZone.time_zone_id} value={timeZone.time_zone_id}>
            <div style={{ display: 'flex', justifyContent: 'space-between', textTransform: 'capitalize' }}>
                {timeZone.name.replace(/_/g, ' ')}
            </div>
        </Option>
    ));

    // Render
    return (
        <Row className={'create-site'}>
            <PageHeader
                className="site-page-header"
                onBack={controller.onBackButtonPressed}
                title={translate({ key: 'site.create-site-title' })}
                subTitle={translate({ key: 'site.create-site-description' })}
            />
            <Form
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                size="middle"
                className={'form'}
                onFinish={controller.onFormSubmit}
            >
                <Form.Item
                    label={
                        <Text type="secondary" strong>
                            {translate({ key: 'label.name' })}
                        </Text>
                    }
                    name="name"
                    required
                    rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                >
                    <Input placeholder={translate({ key: 'form.placeholder.site.name' })} />
                </Form.Item>

                <Col xs={24} id="select-timezone-create-site">
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.timezone' })}
                            </Text>
                        }
                        name="time_zone"
                        required
                        rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                    >
                        <Select
                            showSearch
                            filterOption={(inputValue, option) => {
                                const opt = controller.timeZones.find((item) => item.time_zone_id == option?.value);
                                return !!opt?.name.toLowerCase().includes(inputValue.toLowerCase());
                            }}
                            style={{ width: '100%' }}
                            getPopupContainer={() =>
                                document.getElementById(`select-timezone-create-site`) as HTMLElement
                            }
                            placeholder={translate({ key: 'form.placeholder.site.timezone' })}
                        >
                            {optionTimeZones}
                        </Select>
                    </Form.Item>
                </Col>

                <Form.Item className={'action-box'} style={{ float: 'right' }}>
                    <div style={{ display: 'flex', float: 'right' }}>
                        <Button type={'link'} className={'btn-cancel'} onClick={controller.onCancelButtonPressed}>
                            {translate({ key: 'general.cancel' })}
                        </Button>
                        <Button
                            className={'button primary'}
                            type={'primary'}
                            htmlType="submit"
                            loading={controller.isLoading}
                        >
                            {translate({ key: 'site.create-submit' })}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Row>
    );
};
