import { useEffect, useState } from 'react';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useRfidCardsContext } from 'fragments/rfid-cards-dashboard/context/rfid-cards.context';
import { DeleteRfidCardModalController } from './interfaces';
import { useAPIRfidcard } from 'services/rfidcards/api-rfidcards.service';
import { useAPISee } from 'services/sse/api-sse.service';
import { EventTypes, SseEvent } from 'services/sse/sse.service';
import { useMessenger } from 'tools/view-hooks/messenger-hook';
import { MainError } from 'services/dtos/errors..dto';

export const useDeleteRfidCardModalController = (
    cardsService = useAPIRfidcard(),
    { translate } = useTranslator(),
    messenger = useMessenger(),
    apiSee = useAPISee(),
): /* <--Dependency Injections  like services hooks */
DeleteRfidCardModalController => {
    /* State */

    const [isLoading, setIsLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [event, setEvent] = useState<SseEvent<any>>();
    const [eventSource, setEventSource] = useState<EventSource>();

    const {
        isDeleteModalVisible,
        setIsDeleteModalVisible,
        deleteCardId,
        deleteCardDeviceId,
        getRfidCards,
        selectedDevicesIds,
        selectedRfidCardStatusOptions,
        dateRange,
        cardNumber,
        label,
        authorizer,
    } = useRfidCardsContext();

    /* Listeners */

    useEffect(() => {
        if (eventSource) {
            eventSource.onerror = (event) => {
                eventSource.close();
                if (event) return setStep(-1);
            };
            eventSource.onmessage = (event) => {
                const eventData: SseEvent<any> = JSON.parse(event.data);
                // console.log('Received SSE event:', eventData.event_type);
                switch (eventData.event_type) {
                    case EventTypes.EVENT_CARDS_CARD_LOCK_DELETION_SUCCEEDED:
                        setStep(3);
                        break;
                    case EventTypes.EVENT_CARDS_CARD_LOCK_DELETION_MISSING_ID:
                        setStep(-1);
                        break;
                    case EventTypes.EVENT_CARDS_CARD_LOCK_DELETION_FAILED:
                        setStep(-1);
                        break;
                    case EventTypes.EVENT_TTLOCKAPI_CARD_DELETION_FAILED:
                        setStep(-1);
                        break;
                    case EventTypes.EVENT_CARDS_REMOTE_DELETION_ENDED:
                        eventSource.close();
                        break;
                    default:
                        break;
                }

                setEvent(eventData);
            };
        }
        return () => {
            eventSource?.close();
        };
    }, [eventSource]);

    /* View Events */
    const onCloseButtonPressed = () => {
        setIsDeleteModalVisible(false);
        setStep(0);
        eventSource?.close();
        setEventSource(undefined);
    };

    const onCancelButtonPressed = () => {
        setIsDeleteModalVisible(false);
        setStep(0);
    };

    const onDeleteButtonPressed = () => {
        if (deleteCardId && deleteCardDeviceId) {
            setIsLoading(true);
            setStep(1);
            deleteCardId &&
                cardsService
                    .deleteRfidCard(deleteCardId, deleteCardDeviceId)
                    .then((res) => {
                        setStep(2);
                        setEventSource(apiSee.subscribeEvent(res.event_id));
                    })
                    .catch((err: MainError) => {
                        setStep(-1);
                        if (err.code == 409) {
                            switch (err.msg) {
                                case 'locked':
                                    break;
                                case 'deleted':
                                    break;
                                default:
                                    break;
                            }
                        }
                    });
        } else {
            messenger.showErrorMessage({ key: 'rfid-card.delete-error' });
            eventSource?.close();
            setStep(0);
            setIsDeleteModalVisible(false);
        }
    };

    const onFinishButtonPressed = () => {
        getRfidCards({
            search: {
                devices: selectedDevicesIds,
                since: dateRange[0]?.format('YYYY-MM-DD') || '',
                until: dateRange[1]?.format('YYYY-MM-DD') || '',
                card: cardNumber,
                label: label,
                authorizer: authorizer,
                status: selectedRfidCardStatusOptions,
            },
        });
        eventSource?.close();
        setEventSource(undefined);
        setIsDeleteModalVisible(false);
        setStep(0);
    };

    /* Private Methods */

    // Return state and events
    return {
        isLoading,
        step,
        isDeleteModalVisible,
        onCloseButtonPressed,
        onCancelButtonPressed,
        onDeleteButtonPressed,
        onFinishButtonPressed,
    };
};
