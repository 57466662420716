import { useEffect, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { CheckboxOption, ITag } from 'global/interfaces';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { SiteCardsFilterModalController } from './interfaces';
import { SearchRfidCardsInterface } from 'services/rfidcards/rfidcards.service';
import { useSiteCardsContext } from 'fragments/site-cards-dashboard/context/site-cards.context';

export const useSiteCardsFilterModalController = (): /* <--Dependency Injections  like services hooks */
SiteCardsFilterModalController => {
    /* State */

    const { translate } = useTranslator();
    const [isLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [rfidCardStatusOptions, setRfidCardStatusOptions] = useState<CheckboxOption[]>([]);
    const [checkAll, setCheckAll] = useState(false);
    const [indeterminate, setIndeterminate] = useState(true);
    //________________TAGS________________
    const [tempLockIds, setTempLockIds] = useState<string[]>([]);
    const [tempLabel, setTempLabel] = useState<string[]>([]);
    const [tempCardNumber, setTempCardNumber] = useState<string[]>([]);
    const [tempAuthorizer, setTempAuthorizer] = useState<string[]>([]);
    const [tempDateRange, setTempDateRange] = useState<{ from: string; to: string }>({ from: '', to: '' });
    const [tempStatusIds, setTempStatusIds] = useState<number[]>([]);
    const {
        isFilterModalVisible,
        selectedLockIds,
        dateRange,
        label,
        authorizer,
        pageSize,
        selectedRfidCardStatusOptions,
        cardNumber,
        setCardNumber,
        setSelectedRfidCardStatusOptions,
        setAuthorizer,
        setLabel,
        setIsFilterModalVisible,
        setDateRange,
        setSelectedLockIds,
        setCurrentPage,
        setPageSize,
        setSortedInfo,
        setOrder,
        setOrderBy,
        locks,
        isLoadingLocks,
        getLocks,
        getRfidCards,
        tags,
        setTags,
        rfidCards,
        isLoadingTable,
    } = useSiteCardsContext();

    const [form] = useForm();
    const [session] = useLocalSession();
    const { selectedSite } = session;

    const validateCardNumberRegExp = /^\d+$/;

    /* Listeners */
    // Ex. useEffect(() => { onSessionUpdate(); }, [session]);

    useEffect(() => {
        if (session.token && selectedSite) {
            getLocks();
            setDefaultRfidCardStatusOptions();
        }
    }, [selectedSite]);

    useEffect(() => {
        setSubmitDisabled(disableSubmitButton());
    }, [tempLockIds, tempLabel, tempCardNumber, tempAuthorizer, tempDateRange, tempStatusIds]);

    //________________TAGS________________
    useEffect(() => {
        mapTags({
            devices: selectedLockIds,
            since: dateRange.from,
            until: dateRange.to,
            card: cardNumber,
            label,
            authorizer,
            status: selectedRfidCardStatusOptions,
        });
        setTempLockIds(selectedLockIds);
        setTempLabel(label);
        setTempCardNumber(cardNumber);
        setTempAuthorizer(authorizer);
        setTempDateRange(dateRange);
        setTempStatusIds(selectedRfidCardStatusOptions);
    }, [selectedLockIds, dateRange, label, cardNumber, authorizer, selectedRfidCardStatusOptions, locks]);

    useEffect(() => {
        form.resetFields();
    }, [rfidCards]);

    /* View Events */
    //Ex. const onIncreaseButtonPressed = () => {}

    // ________________Button Events___________________

    const onCancelButtonPressed = () => {
        setTempLockIds(selectedLockIds);
        setTempLabel(label);
        setTempCardNumber(cardNumber);
        setTempAuthorizer(authorizer);
        setTempDateRange(dateRange);
        setTempStatusIds(selectedRfidCardStatusOptions);
        setIsFilterModalVisible(false);
        setIndeterminate(
            !!selectedRfidCardStatusOptions.length &&
                selectedRfidCardStatusOptions.length < defaultRfidCardStatusOptions.length,
        );
        form.resetFields();
    };

    const onResetButtonPressed = () => {
        resetStates();
        setDefaultRfidCardStatusOptions();
        setIsFilterModalVisible(false);
        getRfidCards({
            pagination: { page: 0, pageSize },
            orderTable: { order: 'ascend', orderBy: 'label' },
        });
        form.resetFields();
    };

    const disableSubmitButton = (): boolean => {
        // if (selectedLockIds.length || selectedRfidCardStatusOptions.length) return false;
        // if (dateRange.from || dateRange.to) return false;
        // if (assignedTo.length || authorizer.length) return false;
        if (tempLockIds.length || tempStatusIds.length) return false;
        if (tempDateRange.from || tempDateRange.to) return false;
        if (tempLabel.length || tempAuthorizer.length) return false;
        if (tempCardNumber.length) return false;
        return true;
    };

    const onSubmitButtonPressed = () => {
        setSelectedLockIds(tempLockIds);
        setCardNumber(tempCardNumber);
        setLabel(tempLabel);
        setAuthorizer(tempAuthorizer);
        setDateRange(tempDateRange);
        setSelectedRfidCardStatusOptions(tempStatusIds);
        getRfidCards({
            pagination: { page: 0, pageSize },
            search: {
                devices: tempLockIds,
                since: tempDateRange.from,
                until: tempDateRange.to,
                label: tempLabel,
                card: tempCardNumber,
                authorizer: tempAuthorizer,
                status: tempStatusIds,
            },
        });
        setIsFilterModalVisible(false);
    };
    // ________________Input Events___________________

    const onLockSelect = (value: string[]) => {
        setTempLockIds(value);
        // setSelectedLockIds(value);
    };

    const onCardNumberChange = (value: string[]) => {
        setTempCardNumber(value);
    };

    const onLabelInputChange = (value: string[]) => {
        setTempLabel(value);
        // setAssignedTo(value);
    };

    const onAuthorizerInputChange = (value: string[]) => {
        setTempAuthorizer(value);
        // setAuthorizer(value);
    };

    const onPickedDate = (date: [moment.Moment | null, moment.Moment | null]) => {
        if (date[0] && date[1]) {
            setTempDateRange({ from: date[0].format('YYYY-MM-DD'), to: date[1].format('YYYY-MM-DD') });
        }
        // date && setDateRange({ from: dayjs(date[0]).format('YYYY-MM-DD'), to: dayjs(date[1]).format('YYYY-MM-DD') });
    };

    const onCheckRfidCardStatusOptions = (list: CheckboxValueType[]) => {
        setTempStatusIds(list as number[]);
        setIndeterminate(!!list.length && list.length < rfidCardStatusOptions.length);
        setCheckAll(list.length === rfidCardStatusOptions.length);
    };

    const onCheckAllRfidCardStatusOptions = (e: CheckboxChangeEvent) => {
        setTempStatusIds(e.target.checked ? rfidCardStatusOptions.map((item) => item.value) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };
    /* Private Methods */
    //Ex. const increaseCount = () => {}
    // ________________TAGS___________________
    const onCloseTag = (tag: ITag) => {
        switch (tag.name) {
            case translate({ key: 'label.lock' }): {
                const newSelectedLockIds = selectedLockIds.filter((lockId) => lockId !== tag.id);
                setSelectedLockIds(newSelectedLockIds);
                getRfidCards({
                    pagination: { page: 0, pageSize },
                    search: {
                        devices: newSelectedLockIds,
                        since: dateRange.from,
                        until: dateRange.to,
                        card: cardNumber,
                        label: label,
                        authorizer: authorizer,
                        status: selectedRfidCardStatusOptions,
                    },
                });
                break;
            }
            case translate({ key: 'label.card' }): {
                const newCardNumber = cardNumber.filter((searchTerm) => searchTerm !== tag.id);
                setCardNumber(newCardNumber);
                getRfidCards({
                    pagination: { page: 0, pageSize },
                    search: {
                        devices: selectedLockIds,
                        since: dateRange.from,
                        until: dateRange.to,
                        card: newCardNumber,
                        label: label,
                        authorizer: authorizer,
                        status: selectedRfidCardStatusOptions,
                    },
                });
                break;
            }
            case translate({ key: 'label.label' }): {
                const newLabel = label.filter((searchTerm) => searchTerm !== tag.id);
                setLabel(newLabel);
                getRfidCards({
                    pagination: { page: 0, pageSize },
                    search: {
                        devices: selectedLockIds,
                        since: dateRange.from,
                        until: dateRange.to,
                        card: cardNumber,
                        label: newLabel,
                        authorizer: authorizer,
                        status: selectedRfidCardStatusOptions,
                    },
                });
                break;
            }
            case translate({ key: 'label.responsible' }): {
                const newAuthorizer = authorizer.filter((searchTerm) => searchTerm !== tag.id);
                setAuthorizer(newAuthorizer);
                getRfidCards({
                    pagination: { page: 0, pageSize },
                    search: {
                        devices: selectedLockIds,
                        since: dateRange.from,
                        until: dateRange.to,
                        card: cardNumber,
                        label: label,
                        authorizer: newAuthorizer,
                        status: selectedRfidCardStatusOptions,
                    },
                });
                break;
            }
            case translate({ key: 'label.duration' }): {
                setDateRange({ from: '', to: '' });
                getRfidCards({
                    pagination: { page: 0, pageSize },
                    search: {
                        since: '',
                        until: '',
                        card: cardNumber,
                        label: label,
                        authorizer: authorizer,
                        devices: selectedLockIds,
                        status: selectedRfidCardStatusOptions,
                    },
                });
                break;
            }

            case translate({ key: 'label.state' }): {
                const newSelectedRfidCardStatus = selectedRfidCardStatusOptions.filter((id) => id !== tag.id);
                setSelectedRfidCardStatusOptions(newSelectedRfidCardStatus);
                getRfidCards({
                    pagination: { page: 0, pageSize },
                    search: {
                        devices: selectedLockIds,
                        since: dateRange.from,
                        until: dateRange.to,
                        card: cardNumber,
                        label: label,
                        authorizer: authorizer,
                        status: newSelectedRfidCardStatus,
                    },
                });
                break;
            }
            default:
                break;
        }
    };

    const mapTags = (search: SearchRfidCardsInterface) => {
        const { devices: selectedLockIds, since, until, card, label, authorizer, status } = search;
        const tempLockTags: ITag[] = [];
        const tempLabelTags: ITag[] = [];
        const tempCardTags: ITag[] = [];
        const tempAuthorizerTags: ITag[] = [];
        const tempDateTags: ITag[] = [];
        const tempSelectedRfidCardStatus: ITag[] = [];

        selectedLockIds.map((id) => {
            const selectedLock = locks.find((lock) => lock.id === id);
            tempLockTags.push({
                id,
                name: translate({ key: 'label.lock' }),
                value: `${selectedLock?.name}`,
                color: 'geekblue',
                closable: true,
            });
        });

        label.map((searchTerm) => {
            tempLabelTags.push({
                id: searchTerm,
                name: translate({ key: 'label.label' }),
                value: searchTerm,
                color: 'purple',
                closable: true,
            });
        });

        card.map((searchTerm) => {
            tempCardTags.push({
                id: searchTerm,
                name: translate({ key: 'label.card' }),
                value: searchTerm.toString(),
                color: 'cyan',
                closable: true,
            });
        });

        authorizer.map((searchTerm) => {
            tempAuthorizerTags.push({
                id: searchTerm,
                name: translate({ key: 'label.responsible' }),
                value: searchTerm,
                color: 'purple',
                closable: true,
            });
        });

        if (since !== '' || until !== '') {
            tempDateTags.push({
                id: since + until,
                name: translate({ key: 'label.duration' }),
                value: `Desde: ${since} Hasta: ${until}`,
                color: 'cyan',
                closable: true,
            });
        }
        if (status.length !== rfidCardStatusOptions.length) {
            status.map((value) => {
                const selectedRfidCardStatus = rfidCardStatusOptions.find((option) => option.value === value);
                tempSelectedRfidCardStatus.push({
                    id: value,
                    name: translate({ key: 'label.state' }),
                    value: `${selectedRfidCardStatus?.label}`,
                    color: 'green',
                    closable: true,
                });
            });
            if (tempSelectedRfidCardStatus.length === 1) {
                tempSelectedRfidCardStatus[0].closable = false;
            }
        }

        setTags([
            ...tempLockTags,
            ...tempLabelTags,
            ...tempCardTags,
            ...tempAuthorizerTags,
            ...tempDateTags,
            ...tempSelectedRfidCardStatus,
        ]);
    };

    // ________________Form___________________
    const defaultRfidCardStatusOptions: CheckboxOption[] = [
        { value: 1, label: translate({ key: 'rfid-card.label.valid' }) },
        { value: 2, label: translate({ key: 'rfid-card.label.deleted' }) },
        { value: 3, label: translate({ key: 'rfid-card.label.deleting' }) },
        { value: 4, label: translate({ key: 'rfid-card.label.delete-failed' }) },
    ];
    const setDefaultRfidCardStatusOptions = (): void => {
        setRfidCardStatusOptions(defaultRfidCardStatusOptions);
        setTempStatusIds([1, 4]);
        setSelectedRfidCardStatusOptions([1, 4]);
        setCheckAll(false);
    };

    const resetStates = () => {
        setAuthorizer([]);
        setLabel([]);
        setCardNumber([]);
        setSelectedLockIds([]);
        setDateRange({ from: '', to: '' });
        setCurrentPage(0);
        setPageSize(20);
        setSortedInfo({
            order: 'ascend',
            columnKey: 'label',
        });
        setOrder('ascend');
        setOrderBy('label');
        setDefaultRfidCardStatusOptions();
    };
    // Return state and events
    return {
        tags,
        form,
        isLoading,
        locks,
        isLoadingLocks,
        isFilterModalVisible,
        selectedLockIds,
        label,
        cardNumber,
        tempCardNumber,
        submitDisabled,
        authorizer,
        rfidCardStatusOptions,
        tempStatusIds,
        isLoadingTable,
        dateRange,
        validateCardNumberRegExp,
        onLabelInputChange,
        onCancelButtonPressed,
        onSubmitButtonPressed,
        onResetButtonPressed,
        onCardNumberChange,
        onAuthorizerInputChange,
        onLockSelect,
        onPickedDate,
        onCloseTag,
        checkAll,
        indeterminate,
        onCheckRfidCardStatusOptions,
        onCheckAllRfidCardStatusOptions,
    };
};
