import React from 'react';
import 'fragments/massive-rfid-cards-form/massive-rfid-cards-form.scss';
import { MassiveRfidCardsFormFragmentProps, MiniSiteCardsTableRow } from 'fragments/massive-rfid-cards-form/interfaces';
import { useMassiveRfidCardsFormController } from 'fragments/massive-rfid-cards-form/massive-rfid-cards-form.controller';
import { Button, Checkbox, Col, DatePicker, Form, List, Row, Skeleton, Space, Tag, Tooltip, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { LockDto } from 'services/locks/locks.service';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import InputDebounce from 'components/input-debounce/input-debounce.component';
import { MassiveLoadModalFragment } from './fragments/massive-load-modal/massive-load-modal.fragment';
import { arrayToString } from 'tools/string-handling';

const { Text, Title } = Typography;
const { RangePicker } = DatePicker;

export const MassiveRfidCardsFormFragment: React.FC<MassiveRfidCardsFormFragmentProps> = (props) => {
    const { useController = useMassiveRfidCardsFormController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    const navigate = useNavigate();

    // Render
    return (
        <div className={'massive-rfid-cards'}>
            <Space style={{ width: '100%', marginBottom: '10px', justifyContent: 'space-between' }}>
                <Title level={4} style={{ padding: 0 }}>
                    {translate({ key: 'rfid-card.massive-title' })}
                </Title>
                <Button
                    className={'button primary'}
                    type="primary"
                    style={{ justifySelf: 'flex-end', marginBottom: '10px' }}
                    onClick={() => navigate('/rfidcards')}
                >
                    {translate({ key: 'general.back' })}
                </Button>
            </Space>
            <Form
                id="massive-rfid-cards-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                size="middle"
                className={'massive-rfid-cards-form'}
                form={controller.form}
                onFinish={controller.onCreateButtonPressed}
                disabled={controller.isCreationFormLoading}
            >
                <Col xs={24} id="massive-cards-range-picker">
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.duration' })}
                            </Text>
                        }
                        required
                        name="date_range"
                        rules={[
                            {
                                required: false,
                                message: translate({ key: 'form.rules-required' }),
                            },
                            () => ({
                                validator() {
                                    if (controller.permanentChecked) return Promise.resolve();
                                    const arr = Object.values(controller.selectedDateRange).filter((e) => e === '');
                                    if (!arr.length) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(translate({ key: 'form.rules-required' })));
                                },
                            }),
                        ]}
                    >
                        <RangePicker
                            onCalendarChange={(e) => {
                                e ? controller.onPickedDateRange(e) : controller.onPickedDateRange([null, null]);
                            }}
                            style={{ marginBottom: '10px' }}
                            getPopupContainer={() =>
                                document.getElementById('massive-cards-range-picker') as HTMLElement
                            }
                            format={'DD/MM/YYYY'}
                            disabledDate={controller.disabledDate}
                            disabled={controller.permanentChecked || controller.isCreationFormLoading}
                        />
                        <div>
                            <Checkbox
                                checked={controller.permanentChecked}
                                onChange={controller.onCheckPermanentChange}
                            >
                                {translate({ key: 'label.permanent' })}
                            </Checkbox>
                        </div>
                        <Text type="secondary" strong>
                            {!!controller.formatedDates.sinceDate.length &&
                            !!controller.formatedDates.untilDate.length &&
                            !controller.permanentChecked ? (
                                <>
                                    {translate({ key: 'passcodes.valid-since' })}
                                    <span style={{ color: '#262626d9' }}>
                                        {' ' + controller.formatedDates?.sinceDate + ' '}
                                    </span>
                                    {translate({ key: 'passcodes.until' })}
                                    <span style={{ color: '#262626d9' }}>
                                        {' ' + controller.formatedDates?.untilDate}
                                    </span>
                                </>
                            ) : (
                                ''
                            )}
                        </Text>
                    </Form.Item>
                </Col>

                <div style={{ display: 'flex', alignItems: 'flex-start', gap: '1rem' }}>
                    <Form.Item
                        className={'massive-rfid-cards-form-cards'}
                        style={{ width: '100%', minWidth: '200px' }}
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.cards' })}
                            </Text>
                        }
                        name="card_selection"
                        required
                        rules={[
                            {
                                required: controller.selectedCardsKeys.length < 1,
                                message: translate({ key: 'form.rules-required' }),
                            },
                        ]}
                    >
                        <div className={'massive-rfid-cards-form-cards-container'}>
                            <div className="massive-rfid-cards-form-search-admin">
                                <div style={{ width: '100%' }}>
                                    <InputDebounce
                                        minLength={3}
                                        timeout={1000}
                                        onLoading={controller.onLoadCards}
                                        onSearch={controller.onSearchCards}
                                    />
                                </div>
                                <div style={{ width: 'fit-content' }}>
                                    <Tooltip title={translate({ key: 'rfid-card.manage-description' })}>
                                        <Button
                                            style={{ justifySelf: 'end' }}
                                            type="primary"
                                            onClick={() => navigate('/site-cards')}
                                        >
                                            {translate({ key: 'rfid-card.manage' })}
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div>
                            <div id="scrollableDivCards" className="scrollableDiv">
                                <InfiniteScroll
                                    dataLength={controller.rfidCards.length}
                                    next={controller.loadMoreCards}
                                    hasMore={controller.rfidCards.length < controller.cardsTotal}
                                    loader={<Skeleton paragraph={{ rows: 1 }} active />}
                                    scrollableTarget="scrollableDivCards"
                                >
                                    <List
                                        dataSource={controller.rfidCards}
                                        loading={controller.isLoadingCards}
                                        header={
                                            <List.Item style={{ padding: '0', fontWeight: 'bold' }}>
                                                <List.Item.Meta
                                                    avatar={
                                                        <Checkbox
                                                            onChange={(e) => {
                                                                e.target.checked
                                                                    ? controller.onSelectCardsChange(
                                                                          controller.rfidCards.map((rc) => rc.id),
                                                                      )
                                                                    : controller.onSelectCardsChange([]);
                                                            }}
                                                        />
                                                    }
                                                />
                                                <Row style={{ width: '100%' }}>
                                                    <Col
                                                        span={12}
                                                        style={{ borderLeft: '1px solid  rgba(140, 140, 140, 0.35)' }}
                                                    >
                                                        <Text style={{ padding: '0 1rem' }}>
                                                            {translate({ key: 'label.label' })}
                                                        </Text>
                                                    </Col>
                                                    <Col
                                                        span={12}
                                                        style={{ borderLeft: '1px solid  rgba(140, 140, 140, 0.35)' }}
                                                    >
                                                        <Text style={{ paddingLeft: '1rem' }}>
                                                            {translate({ key: 'label.card-number' })}
                                                        </Text>
                                                    </Col>
                                                    {/* <Col
                                                            span={6}
                                                            style={{ borderLeft: '1px solid  rgba(140, 140, 140, 0.35)' }}
                                                        >
                                                            <Text style={{ paddingLeft: '1rem' }}>
                                                                {translate({ key: 'label.actions' })}
                                                            </Text>
                                                        </Col> */}
                                                </Row>
                                            </List.Item>
                                        }
                                        renderItem={(item: MiniSiteCardsTableRow) => (
                                            <List.Item key={item.id}>
                                                <List.Item.Meta
                                                    avatar={
                                                        <Checkbox
                                                            onChange={(e) => {
                                                                e.target.checked
                                                                    ? controller.onSelectCardsChange([
                                                                          ...controller.selectedCardsKeys,
                                                                          item.id as React.Key,
                                                                      ])
                                                                    : controller.onSelectCardsChange(
                                                                          controller.selectedCardsKeys.filter(
                                                                              (k) => k !== item.id,
                                                                          ),
                                                                      );
                                                            }}
                                                            checked={controller.selectedCardsKeys.some(
                                                                (k) => k === item.id,
                                                            )}
                                                        />
                                                    }
                                                />
                                                <Row style={{ width: '100%' }}>
                                                    <Col
                                                        span={12}
                                                        style={{ borderLeft: '1px solid  rgba(140, 140, 140, 0.35)' }}
                                                    >
                                                        <div style={{ padding: '0 1rem' }}>{item.label}</div>
                                                    </Col>
                                                    <Col
                                                        span={12}
                                                        style={{ borderLeft: '1px solid  rgba(140, 140, 140, 0.35)' }}
                                                    >
                                                        <Text style={{ paddingLeft: '1rem' }}>{item.card_number}</Text>
                                                    </Col>
                                                    {/* <Col
                                                            span={6}
                                                            style={{
                                                                borderLeft: '1px solid  rgba(140, 140, 140, 0.35)',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            <Button
                                                                icon={<EditOutlined />}
                                                                style={{
                                                                    marginLeft: '0.5rem',
                                                                    minWidth: '2rem',
                                                                }}
                                                                onClick={() => {
                                                                    controller.onEditButtonPressed(item.card_number);
                                                                }}
                                                            />
                                                            <Button
                                                                icon={<UserSwitchOutlined />}
                                                                style={{
                                                                    marginLeft: '0.5rem',
                                                                    minWidth: '2rem',
                                                                }}
                                                                onClick={() => {
                                                                    controller.onSwitchUserButtonPressed(item.card_number);
                                                                }}
                                                            />
                                                        </Col> */}
                                                </Row>
                                            </List.Item>
                                        )}
                                    />
                                </InfiniteScroll>
                            </div>
                        </div>
                        <div
                            style={{
                                minHeight: '100px',
                                maxHeight: '150px',
                                overflowY: 'scroll',
                                padding: '16px',
                                border: '1px solid rgba(140, 140, 140, 0.35)',
                                marginTop: '0.5rem',
                            }}
                        >
                            <div>
                                <Text strong>{`${translate({ key: 'label.selected' })}: `}</Text>
                                {controller.selectedCardsKeys.length}
                            </div>
                            {controller.cardsTags.map((tag, i) => {
                                const labelArr = tag.split(',');
                                return (
                                    <Tag key={i} style={{ fontWeight: 'bold' }}>
                                        <Text strong>{arrayToString(labelArr, translate({ key: 'general.and' }))}</Text>
                                    </Tag>
                                );
                            })}
                        </div>
                    </Form.Item>
                    <Form.Item
                        style={{ width: '100%', minWidth: '200px' }}
                        label={
                            <Text type="secondary" strong>
                                {translate({ key: 'label.lock' })}
                            </Text>
                        }
                        name="lock_selection"
                        required
                        rules={[
                            {
                                required: controller.selectedLocksKeys.length < 1,
                                message: translate({ key: 'form.rules-required' }),
                            },
                        ]}
                    >
                        <div className={'massive-rfid-cards-form-cards-container'}>
                            <div className="massive-rfid-cards-form-search-admin">
                                <InputDebounce
                                    minLength={3}
                                    timeout={1000}
                                    onLoading={controller.onLoadLocks}
                                    onSearch={controller.onSearchLocks}
                                />
                            </div>
                            <div id="scrollableDivLocks" className="scrollableDiv">
                                <InfiniteScroll
                                    dataLength={controller.locks.length}
                                    next={controller.loadMoreLocks}
                                    hasMore={controller.locks.length < controller.locksTotal}
                                    loader={<Skeleton paragraph={{ rows: 1 }} active />}
                                    scrollableTarget="scrollableDivLocks"
                                >
                                    <List
                                        dataSource={controller.locks}
                                        loading={controller.isLoadingLocks}
                                        header={
                                            <List.Item style={{ padding: '0', fontWeight: 'bold' }}>
                                                <List.Item.Meta
                                                    avatar={
                                                        <Checkbox
                                                            onChange={(e) => {
                                                                e.target.checked
                                                                    ? controller.onSelectLocksChange(
                                                                          controller.locks.map((l) => l.id),
                                                                      )
                                                                    : controller.onSelectLocksChange([]);
                                                            }}
                                                        />
                                                    }
                                                />
                                                <Row style={{ width: '100%' }}>
                                                    <Col
                                                        span={24}
                                                        style={{ borderLeft: '1px solid  rgba(140, 140, 140, 0.35)' }}
                                                    >
                                                        <Text style={{ paddingLeft: '1rem' }}>
                                                            {translate({ key: 'label.name' })}
                                                        </Text>
                                                    </Col>
                                                </Row>
                                            </List.Item>
                                        }
                                        renderItem={(item: LockDto) => (
                                            <List.Item key={item.id}>
                                                <List.Item.Meta
                                                    avatar={
                                                        <Checkbox
                                                            onChange={(e) => {
                                                                e.target.checked
                                                                    ? controller.onSelectLocksChange([
                                                                          ...controller.selectedLocksKeys,
                                                                          item.id as React.Key,
                                                                      ])
                                                                    : controller.onSelectLocksChange(
                                                                          controller.selectedLocksKeys.filter(
                                                                              (k) => k !== item.id,
                                                                          ),
                                                                      );
                                                            }}
                                                            checked={controller.selectedLocksKeys.some(
                                                                (k) => k === item.id,
                                                            )}
                                                        />
                                                    }
                                                />
                                                <Row style={{ width: '100%' }}>
                                                    <Col
                                                        span={24}
                                                        style={{ borderLeft: '1px solid rgba(140, 140, 140, 0.35)' }}
                                                    >
                                                        <Text style={{ paddingLeft: '1rem' }}>{item.name}</Text>
                                                    </Col>
                                                </Row>
                                            </List.Item>
                                        )}
                                    />
                                </InfiniteScroll>
                            </div>
                        </div>
                        <div
                            style={{
                                minHeight: '100px',
                                maxHeight: '150px',
                                overflowY: 'scroll',
                                padding: '16px',
                                border: '1px solid rgba(140, 140, 140, 0.35)',
                                marginTop: '0.5rem',
                            }}
                        >
                            <div>
                                <Text strong>{`${translate({ key: 'label.selected' })}: `}</Text>
                                {controller.selectedLocksKeys.length}
                            </div>
                            {controller.locksTags.map((tag, i) => (
                                <Tag key={i} style={{ fontWeight: 'bold' }}>
                                    <Text strong>{tag}</Text>
                                </Tag>
                            ))}
                        </div>
                    </Form.Item>
                </div>
            </Form>
            <Row justify="end">
                <Button
                    form="massive-rfid-cards-form"
                    key="submit"
                    type="primary"
                    className={'button primary'}
                    loading={controller.isCreationFormLoading}
                    htmlType="submit"
                >
                    {translate({ key: 'general.add' })}
                </Button>
            </Row>
            <MassiveLoadModalFragment resetForm={controller.reloadForm} />
        </div>
    );
};
