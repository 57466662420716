import React from 'react';
import 'fragments/app-bar/app-bar.scss';
import { AppBarFragmentProps } from 'fragments/app-bar/interfaces';
import { useAppBarController } from 'fragments/app-bar/app-bar.controller';
import { Link } from 'react-router-dom';
import { Drawer, Button, Layout, Row, Col, Select, Divider, Typography } from 'antd';
import { MenuOutlined, SettingFilled } from '@ant-design/icons';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { NavigationMenu } from 'navigators/navigation/navigation';
import { useSitesContext } from 'fragments/sites-modal/context/sites.context';

const { Header } = Layout;
const { Option } = Select;
const { Text } = Typography;

export const AppBarFragment: React.FC<AppBarFragmentProps> = (props) => {
    const { useController = useAppBarController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    const { sites } = useSitesContext();

    const optionSites = sites.map((site) => (
        <Option key={site.id} value={site.id}>
            <div style={{ display: 'flex', justifyContent: 'space-between', textTransform: 'capitalize' }}>
                {site.name}
            </div>
        </Option>
    ));

    const MobileMenu = (
        <div>
            <Divider plain>
                <Text strong> {translate({ key: 'nav.site-select' })}</Text>
            </Divider>
            <Col xs={24} id="select-site-mobile-menu" style={{ margin: '1rem' }}>
                <Select
                    showSearch
                    filterOption={(inputValue, option) => {
                        const opt = sites.find((item) => item.id == option?.value);
                        return !!opt?.name.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                    style={{ width: '100%' }}
                    getPopupContainer={() => document.getElementById(`select-site-mobile-menu`) as HTMLElement}
                    placeholder={translate({ key: 'form.placeholder-sites' })}
                    value={controller.selectedSite.id}
                    onChange={controller.onSiteSelect}
                    dropdownRender={(menu) => (
                        <div>
                            {menu}
                            <Divider style={{ margin: '4px 0' }} />
                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 0 }}>
                                <Button
                                    className={'admin-sites-btn'}
                                    type="text"
                                    onClick={controller.onAdminSitesButtonPressed}
                                >
                                    <SettingFilled /> {translate({ key: 'special.admin-sites' })}
                                </Button>
                            </div>
                        </div>
                    )}
                >
                    {optionSites}
                </Select>
            </Col>
            <NavigationMenu translate={translate} controller={controller} />
        </div>
    );

    // Render
    return (
        <Header className={'app-bar'}>
            <Row justify="space-between">
                <Col xs={3} className={'only-mobile'}>
                    <Button
                        type="primary"
                        className={'only-mobile'}
                        icon={<MenuOutlined />}
                        onClick={controller.onSideMenuButtonPressed}
                        style={{ boxShadow: 'none' }}
                    />
                </Col>
                <Drawer
                    title={
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Link to={'/'}>
                                <img src="logo-negro.png" width={150} />
                            </Link>
                        </div>
                    }
                    placement="left"
                    closable={false}
                    onClose={controller.onOutsideDrawerClick}
                    open={controller.sideMenu}
                    bodyStyle={{ padding: 0 }}
                >
                    {MobileMenu}
                </Drawer>
                <Col>
                    <Link to={'/'}>
                        <img src={'logo-bco.png'} className={'header-img'} />
                    </Link>
                </Col>
                <Col />
            </Row>
        </Header>
    );
};
