import { useEffect, useState } from 'react';
import { AccessReportsDashboardController, AccessReportTableRow } from 'fragments/access-reports-dashboard/interfaces';
import { AccessReportDto } from 'services/access-reports/access-reports.service';
import { hardcodeSiteId } from 'services/access-reports/api-access-reports.service';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { capitalize } from 'tools/string-handling';
import { useAccessReportsContext } from './context/access-reports.context';
import { TablePaginationConfig } from 'antd/es/table';
import dayjs from 'dayjs';
import { useTranslator } from 'tools/view-hooks/translator-hook';

export const useAccessReportsDashboardController = (
    { translate } = useTranslator(),
): AccessReportsDashboardController => {
    /* State */
    // Ex. const [count, setCount] = useState(0);
    const {
        dateRange,
        setDateRange,
        searchInput,
        setSearchInput,
        selectedLockIds,
        setSelectedLockIds,
        accessReports,
        setAccessReports,
        currentPage,
        pageSize,
        totalReports,
        setTotalReports,
        isTableLoading,
        sortedInfo,
        setIsFilterModalVisible,
        setSortedInfo,
        getAccessReports,
        getLocks,
        toggleTable,
        setToggleTable,
        selectedAccessMethodIds,
        selectedReportStateIds,
    } = useAccessReportsContext();
    const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);

    const [session] = useLocalSession();
    const { selectedSite } = session;

    /* Listeners */

    useEffect(() => {
        if (session.token && session.selectedSite) {
            setIsSuperAdmin(session.user_profile?.role === 'superadmin');
            setAccessReports([]);
            setTotalReports(0);
            setDateRange({ from: '', to: '' });
            setSelectedLockIds([]);
            setSearchInput('');
            if (!!hardcodeSiteId.find((id) => id === session.selectedSite?.id)) {
                setToggleTable(true);
            } else {
                setToggleTable(false);
            }

            getAccessReports({
                pagination: { page: 0, pageSize: 20 },
                orderTable: {
                    order: 'descend',
                    orderBy: 'date',
                },
                search: {
                    startDate: '',
                    endDate: '',
                    searchInput: '',
                    locks: [],
                    accessMethodIds: [],
                    reportStateIds: [],
                },
            });
        }
    }, [selectedSite]);

    /* View Events */

    const onOpenFilterModal = () => {
        getLocks();
        setIsFilterModalVisible(true);
    };

    const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        const page = pagination.current ? pagination.current - 1 : 0;
        const currentPageSize = Number(pagination.pageSize);
        setSortedInfo({
            order: sorter.order,
            columnKey: sorter.column?.key,
        });
        getAccessReports({
            pagination: { page: currentPage === page ? 0 : page, pageSize: currentPageSize },
            orderTable: {
                order: sorter.order,
                orderBy: sorter.column?.key,
            },
            search: {
                startDate: dateRange.from,
                endDate: dateRange.to,
                searchInput,
                locks: selectedLockIds,
                accessMethodIds: selectedAccessMethodIds,
                reportStateIds: selectedReportStateIds,
            },
        });
    };

    /* Private Methods */
    const reportTypeToString = (type: number): string => {
        switch (type) {
            case 1:
                return translate({ key: 'access-reports.access-method-option.app' });
            case 4:
                return translate({ key: 'access-reports.access-method-option.passcode' });
            case 7:
                return translate({ key: 'access-reports.access-method-option.rfid' });
            case 8:
                return translate({ key: 'access-reports.access-method-option.fingerprint' });
            case 12:
                return translate({ key: 'access-reports.access-method-option.remote-access' });
            case 46:
                return translate({ key: 'access-reports.access-method-option.button' });
            default:
                return translate({ key: 'general.unknown' });
        }
    };

    const reportStatusToString = (status: number): string => {
        switch (status) {
            case 0:
                return translate({ key: 'access-reports.report-state-option.error' });
            case 1:
                return translate({ key: 'access-reports.report-state-option.success' });
            default:
                return translate({ key: 'general.unknown' });
        }
    };

    const mapDtoToTableRow = (dto: AccessReportDto): AccessReportTableRow => {
        const name = toggleTable
            ? dto.type == 1
                ? `${capitalize(dto.full_name)}` || '-'
                : dto.identifier || '-'
            : capitalize(dto.full_name);
        return {
            id: dto.id,
            userEmail: dto.user_email || '-',
            userFullName: name,
            lockName: dto.lock_name,
            reportDateTime:
                dayjs.utc(dto.report_datetime).format('DD/MM/YYYY HH:mm') + ' (' + selectedSite?.time_zone + ')',
            type: reportTypeToString(typeof dto.type === 'number' ? dto.type : -1),
            typeId: dto.type || -1,
            success: reportStatusToString(typeof dto.success === 'number' ? dto.success : -1),
            identifier: '',
        };
    };

    // Return state and events
    return {
        isTableLoading,
        accessReports,
        pageSize,
        currentPage,
        totalReports,
        toggleTable,
        sortedInfo,
        isSuperAdmin,
        onOpenFilterModal,
        handleTableChange,
        itemsViewModel: accessReports.map(mapDtoToTableRow),
    };
};
